import PickerOverlay from 'filestack-react'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DocumentTitle from 'react-document-title'
import {
  Avatar,
  Button,
  Card,
  CardText,
  CardTitle,
  FontIcon,
  Media,
  Paper,
  Toolbar
} from 'react-md'
import { connect } from 'react-redux'
import { actions, Errors, Form } from 'react-redux-form'
import { push } from 'react-router-redux'

import AddressForm from 'components/AddressForm'
import InputTextField from 'components/InputTextField'
import LocaleForm from 'components/LocaleForm'
import MultilangWysiwyg from 'components/MultilangWysiwyg'
import TooltipButton from 'components/TooltipButton'
import { hostUIMessages } from 'constants/UIConstants'
import { copyItem, saveItem, showItems } from 'redux/modules/crud'
import blankEditStates from 'redux/modules/crud/blankEditStates'
import { fixRotation } from 'redux/modules/filestack'
import currentUser from 'utils/CurrentUser'
import { resizeImage } from 'utils/Image'
import { requiredIf } from 'utils/ValidationHelpers'

import FormCardActions from './FormCardActions'
import ManageSelectedGuidebooks from './ManageSelectedCards'
import ManageGuidebooksAndTemplates from './ManageSelectedCards/ManageGuidebooksAndTemplates'

class ListingForm extends Component {
  constructor(props) {
    super(props)
    this.imageUploaded = this.imageUploaded.bind(this)
    this.goBack = this.goBack.bind(this)
    this.copyCard = this.copyCard.bind(this)
    this.handleEnter = this.handleEnter.bind(this)
    this.saveAndAddAnother = this.saveAndAddAnother.bind(this)
    this.state = {
      showAddressForm: true,
      addressValue: {}
    }
  }

  formatMessage = this.props.intl.formatMessage
  // images sizes for preloading
  imageSizes = [
    [748, 561],
    [480, 360],
    [348, 261],
    [320, 240],
    [132, 99],
    [100, 100],
    [60, 60],
    [50, 50],
    [30, 30]
  ]

  async componentDidMount() {
    this.mounted = true
    const { dispatch, itemId, guidebookId } = this.props
    if (itemId === 'create') {
      let newItem = blankEditStates.listing.data
      if (guidebookId && guidebookId !== 'create') {
        // if creating from a guidebook, select the guidebook by default
        newItem = Object.assign({}, newItem, {
          guidebooks: [{ id: guidebookId }]
        })
      }
      // also if we're making a new one and our "owner" has locales, default to those
      if (this.props.owner_data && this.props.owner_data.locales.length > 0) {
        newItem = Object.assign({}, newItem, {
          locales: this.props.owner_data.locales
        })
      }
      dispatch(actions.load('edit_listing', newItem))
    }
  }

  componentWillReceiveProps(nextProps) {
    const listing = this.props.edit_listing
    const nextListing = nextProps.edit_listing
    // if the listing has loaded, and the address is null
    if (typeof listing.id === 'undefined' && nextListing.id) {
      if (nextListing.address === null) {
        this.setState({ showAddressForm: false })
      }
    }
  }

  goBack = () => {
    const { dispatch, pluralName, guidebookId, ownerPluralName } = this.props
    if (guidebookId) {
      const path = '#listings#listing'
      dispatch(push(`/host/${ownerPluralName}/${guidebookId}${path}`))
    } else if (window.isIframe) {
      window.history.back()
    } else {
      dispatch(showItems(pluralName))
    }
  }

  copyCard = (id) => {
    const { dispatch, pluralName } = this.props
    dispatch(copyItem(pluralName, id))
  }

  handleEnter = (e) => {
    const { dispatch } = this.props
    e.preventDefault()
    console.log('handleEnter');
    dispatch(actions.submit('edit_listing'))
  }

  imageUploaded(uploadResult) {
    const { dispatch } = this.props
    if (uploadResult.filesUploaded && uploadResult.filesUploaded.length > 0) {
      const file = uploadResult.filesUploaded[0]
      const filestackUrl = file.url
      fixRotation(filestackUrl).then((data) => {
        if (data.url && data.url.length > 0) {
          dispatch(actions.change('edit_listing.image', data.url))
        }
      })
    }
  }

  removeImage = () => {
    const { dispatch } = this.props
    dispatch(actions.change('edit_hostintro.image', null))
  }

  handleSubmit(item) {
    const { itemId, dispatch, guidebookId, copy } = this.props
    const id = copy ? null : itemId
    const addAnother = this.addAnother
    this.addAnother = false
    const currentGuidebook = guidebookId ? this.props.owner_data : null
    console.log(item);
    dispatch(
      saveItem(
        'listings',
        'listing',
        id,
        item,
        addAnother,
        currentGuidebook
      )
    )
  }

  formHasErrors = (formErrors) => {
    const edit_form = this.props.edit_form
    for (var field in formErrors) {
      if (field.substring(0, 7) === 'address') {
        var subField = field.split('.').pop()
        if (edit_form.address[subField] && !edit_form.address[subField].valid) {
          return false
        }
      } else {
        if (edit_form[field] && !edit_form[field].valid) {
          return false
        }
      }
    }
    return true
  }

  saveAndAddAnother = (e) => {
    const { dispatch } = this.props
    this.addAnother = true
    dispatch(actions.submit('edit_listing'))
  }

  componentWillUnmount() {
    this.mounted = false
    //clear redux state for next item
    this.props.dispatch(actions.reset('edit_listing'))
  }

  render() {
    const self = this
    const {
      address,
      copy,
      edit_listing,
      guidebookId,
      itemId,
      ownerPluralName
    } = this.props
    const locales = edit_listing.locales
    const image = edit_listing.image
    const user = currentUser()

    const title = copy
      ? 'Copy listing card'
      : itemId === 'create'
        ? 'New listing card'
        : 'Edit listing card'
    const nav = (
      <TooltipButton
        key="nav"
        onClick={self.goBack}
        tooltipLabel="Back"
        tooltipPosition="bottom"
        icon
      >
        <FontIcon>keyboard_backspace</FontIcon>
      </TooltipButton>
    )

    const actions = []
    const formErrors = []
    const errorMessages = {
      formwide: {
        valid: 'Oops! Unable to save:'
      },
      name: {
        required: 'Please provide a title'
      },
      description: {
        required: 'Please provide a listing description',
        length: 'Please use a maximum of 240 characters in your description'
      },
      booking_link: {
        valid: 'Please enter a valid booking link. It must begin with http or https'
      },
      street: {
        required: 'Please provide a street address'
      },
      locality: {
        required: 'Please provide a city / suburb'
      },
      country_code: {
        required: 'Please provide a country'
      },
      lat: {
        required: 'Please provide a map location'
      }
    }
    formErrors['formwide'] = (
      <Errors
        model="edit_listing"
        messages={errorMessages.formwide}
        wrapper="h2"
        className="md-text-field-message title hf-error"
        show={(form) => form.submitFailed}
      />
    )
    formErrors['name'] = (
      <Errors
        model=".name"
        messages={errorMessages.name}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['booking_link'] = (
      <Errors
        model=".booking_link"
        messages={errorMessages.booking_link}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['description'] = (
      <Errors
        model=".description"
        messages={errorMessages.description}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['address.street'] = (
      <Errors
        model=".address.street"
        messages={errorMessages.street}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['address.locality'] = (
      <Errors
        model=".address.locality"
        messages={errorMessages.locality}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['address.country_code'] = (
      <Errors
        model=".address.country_code"
        messages={errorMessages.country_code}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['address.lat'] = (
      <Errors
        model=".address.lat"
        messages={errorMessages.lat}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )

    if (edit_listing.id && user.canCopy && !copy && !guidebookId) {
      actions.push(
        <TooltipButton
          key="copy"
          onClick={() => {
            self.copyCard(edit_listing.id)
          }}
          tooltipLabel="Copy"
          tooltipPosition="bottom"
          icon
        >
          <FontIcon>content_copy</FontIcon>
        </TooltipButton>
      )
    }
    const filestackKey = process.env.REACT_APP_FILESTACK_KEY
    const filestackOptions = {
      accept: 'image/*',
      maxFiles: 1,
      fromSources: ['local_file_system', 'url', 'imagesearch'],
      storeTo: {
        location: 'gcs'
      },
      imageMax: [1600, 1200],
      transformations: {
        crop: {
          aspectRatio: 4 / 3,
          force: true
        },
        rotate: true
      }
    }

    const listing_address = address || {}
    const validators = {
      '': {
        // Form-level validator
        valid: () => this.formHasErrors(formErrors)
      },
      name: {
        required: (val) => val && val.length
      },
      description: {
        required: (val) => val && val.length,
        length: (val) => val && val.length <= 240
      },
      booking_link: {
        valid: (val) => {
          if (val) {
            if (
              val.substring(0, 7) !== 'http://' &&
              val.substring(0, 8) !== 'https://'
            ) {
              return false
            } else {
              return true;
            }
          }
          return false;
        }
      },
      'address.locality': {
        required: (val) =>
          requiredIf(this.state.showAddressForm, val && val.length)
      },
      'address.country_code': {
        required: (val) =>
          requiredIf(this.state.showAddressForm, val && val.length)
      },
      'address.lat': {
        required: (val) =>
          requiredIf(this.state.showAddressForm, val && val !== '')
      }
    }

    const titlePrompt = 'Listing Name'
    const imagePrompt = 'Listing Image'
    const urlPrompt = 'Booking Link'

    const preview_image = image ? (
      <Media aspectRatio="4-3">
        <img src={resizeImage(image, 320, 240, true)} alt="" />
      </Media>
    ) : (
      <Media aspectRatio="4-3">
        <img
          src="https://storage.googleapis.com/hostfully-wp-1/blank-state-images.png"
          alt=""
        />
      </Media>
    )

    const removeStyle = { marginLeft: '12px' }
    const removeButton = image ? (
      <Button style={removeStyle} onClick={this.removeImage} raised secondary>
        Remove Image
      </Button>
    ) : null

    const providerStyle = { marginBottom: '24px' }
    const providerData =
        <div className="md-cell md-cell--12" style={providerStyle}>
          <div className="md-grid md-grid--no-spacing">
            <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet" />
            <div className="md-cell md-cell--5 md-cell--3-tablet md-cell--4-phone">
              <InputTextField
                model=".external_provider"
                id="external_provider"
                label="External Provider"
              />
            </div>

            <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet" />
            <div className="md-cell md-cell--5 md-cell--3-tablet md-cell--4-phone">
              <InputTextField
                model=".external_id"
                id="external_id"
                label="External Id"
              />
            </div>
          </div>
        </div>

    return (
      <DocumentTitle title="Book Again Listing">
        <div className="hf-listings-paper">
          <Toolbar
            colored
            className="hf-edit-toolbar hf-listings"
            title={title}
            nav={nav}
            actions={actions}
          />

          <Paper key="category" className="hf-form-wrapper">
            <Form
              model="edit_listing"
              onSubmit={(v) => this.handleSubmit(v)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  return this.handleEnter(e)
                }
              }}
              validators={validators}
            >
              <div className="md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">{titlePrompt}</h2>
                  <p className="md-body-1">
                    Required - Guests will see this heading at the top of the card
                  </p>
                  <MultilangWysiwyg
                    simpleEditor
                    model="edit_listing"
                    field="name"
                    txn_field="name_txn"
                    locales={locales}
                    label="Listing name *"
                  />
                  {formErrors['name']}
                </div>
               
              </div>
              {this.state.showAddressForm ? (
                <AddressForm
                  editModel="edit_listing"
                  address={listing_address}
                  image={image}
                  onPlaceImagesUpdated={() => {}}
                  onPlaceTypeUpdated={() => {}}
                />
              ) : null}
              <div className="md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    {imagePrompt}
                  </h2>
                  <div className="md-grid">
                    <div className="md-cell md-cell--4">{preview_image}</div>
                    <div className="md-cell md-cell--8">
                      <PickerOverlay
                        apikey={filestackKey}
                        componentDisplayMode={{
                          type: 'button',
                          customText: 'Select Image',
                          customClass:
                            'md-inline-block md-btn md-btn--raised md-background--primary md-background--primary-hover md-pointer--hover md-btn--text md-btn--raised-pressed'
                        }}
                        actionOptions={filestackOptions}
                        onSuccess={this.imageUploaded}
                      />
                      {removeButton}
                    </div>
                  </div>
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    Listing Description
                  </h2>
                  <p className="md-body-1">
                    Required - Guests will see this quoted on the card
                  </p>
                  <p className="hf-notice">
                    Note: If you are picky about the cards on the page lining up
                    exactly, you will want to normalize the amount of text you
                    use in this field
                  </p>
                  <MultilangWysiwyg
                    simpleEditor
                    id="description"
                    model="edit_listing"
                    field="description"
                    txn_field="description_txn"
                    max_length={240}
                    locales={locales}
                  />
                  {formErrors['description']}
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">{urlPrompt}</h2>
                  <p className="md-body-1">
                    Required - This is the booking page that this card will open when clicked
                  </p>
                  <InputTextField
                    model=".booking_link"
                    id="booking_link"
                    label="Enter booking link here"
                  />
                  {formErrors['booking_link']}
                </div>

                <div className="md-cell md-cell--12 hf-headline-margin">
                  <Card className="hf-wider-selection-control-container">
                    <CardTitle
                      avatar={
                        <Avatar
                          icon={<FontIcon>settings</FontIcon>}
                          alt=""
                          suffix="hfpurple"
                        />
                      }
                      title="Provider settings"
                    />
                    <CardText>
                      <div
                        className="md-grid md-grid--no-spacing"
                        id="provider"
                      >
                        {providerData}
                      </div>
                    </CardText>
                  </Card>
                </div>
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <h2 className="md-headline">
                    {this.formatMessage(hostUIMessages['languageHeading'])}
                  </h2>
                  <p className="md-body-1">
                    {this.formatMessage(hostUIMessages['languageSubHeading'])}
                  </p>
                  <LocaleForm editModel="edit_listing" />
                </div>
              </div>
              <div className="md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  {user.isEnterprise ? (
                    <ManageGuidebooksAndTemplates
                      ownerPluralName="listings"
                      ownerSingularName="listing"
                      guidebookId={guidebookId}
                      cardType="Listing"
                      activeTab={ownerPluralName}
                      active={true}
                    />
                  ) : (
                    <ManageSelectedGuidebooks
                      ownerPluralName="listings"
                      ownerSingularName="listing"
                      guidebookId={guidebookId}
                      cardType="Listing"
                      active={true}
                    />
                  )}
                </div>
              </div>
              <div className="md-grid hf-headline-margin">
                <div className="md-cell md-cell--12">
                  <div>{formErrors['formwide']}</div>
                  <div>{formErrors['name']}</div>
                  <div>{formErrors['description']}</div>
                  <div>{formErrors['booking_link']}</div>
                  <div>{formErrors['address.lat']}</div>
                  <div>{formErrors['address.street']}</div>
                  <div>{formErrors['address.locality']}</div>
                  <div>{formErrors['address.country_code']}</div>
                </div>
              </div>
              <FormCardActions saveAndAdd={this.saveAndAddAnother} />
            </Form>
          </Paper>
        </div>
      </DocumentTitle>
    )
  }
}

ListingForm.propTypes = {
  ownerSingularName: PropTypes.string,
  ownerPluralName: PropTypes.string
}

function mapStateToProps(state, props) {
  const { ownerSingularName } = props
  const edit_listing = state.edit_listing
  const owner_data = state[`edit_${ownerSingularName}`]
  const locales = edit_listing.locales
  const address = edit_listing.address
  const image = edit_listing.image
  const name = edit_listing.name
  const edit_form = state.forms.edit_listing

  return {
    edit_listing,
    owner_data,
    locales,
    address,
    image,
    name,
    edit_form
  }
}

export default connect(mapStateToProps)(ListingForm)
