import PickerOverlay from 'filestack-react'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withCookies } from 'react-cookie'
import CopyToClipboard from 'react-copy-to-clipboard'
import { injectIntl, intlShape } from 'react-intl'
import {
  Avatar,
  Button,
  Card,
  CardText,
  CardTitle,
  DialogContainer,
  FontIcon,
  LinearProgress,
  List,
  ListItem,
  Media,
  SelectField,
  Switch
} from 'react-md'
import { connect } from 'react-redux'
import { actions, Errors, Form } from 'react-redux-form'
import { replace } from 'react-router-redux'

import AddressForm from 'components/AddressForm'
import HfSwitchForm from 'components/HfSwitchForm'
import InputTextField from 'components/InputTextField'
import LinkModal from 'components/LinkModal'
import LocaleForm from 'components/LocaleForm'
import SelectForm from 'components/SelectForm'
import TooltipFontIcon from 'components/TooltipFontIcon'
import TooltipSpan from 'components/TooltipSpan'
import { localeMessages, wizardSteps } from 'constants/UIConstants'
import {
  apiError,
  autoSaveItem,
  fetchItem,
  fetchItems,
  newGuidebookWithTemplate,
  saveItem
} from 'redux/modules/crud'
import blankEditStates from 'redux/modules/crud/blankEditStates'
import { fixRotation } from 'redux/modules/filestack'
import { fetchMarketplaceItems } from 'redux/modules/marketplace'
import {
  autoCreateTokens,
  detachPropertyFromGuidebook
} from 'redux/modules/orbirental/connect'
import { fetchProviders } from 'redux/modules/orbirental/crud'
import { checkStatus } from 'redux/modules/orbirental/status'
import { fetchProperties } from 'redux/modules/properties'
import { generateRecs } from 'redux/modules/recsWizard'
import { addToast } from 'redux/modules/toast'
import { checkVCSStatus, reImportGuidebook } from 'redux/modules/vcs'
import currentUser from 'utils/CurrentUser'
import { jsonEqual, sortData } from 'utils/Data'
import { preloadImages, resizeImage } from 'utils/Image'
import { isNumericKey, isValidUrlString } from 'utils/Strings'
import { buildGuidebookUrl } from 'utils/Urls'

import GuidebookStatus from './GuidebookStatus'
import GuidebookStepper from './GuidebookStepper'
import GuidebookWizard from './GuidebookWizard'
import ManageInformations from './ManageInformations'
import ManageListings from './ManageListings'
import ManageRecommendations from './ManageRecommendations'
import MarketplaceSection from './MarketplaceSection'

import ManageSelectedGuidebooks from '../ManageSelectedCards'

const propTypes = {
  intl: intlShape.isRequired,
  dispatch: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
  copy: PropTypes.func,
  edit_form: PropTypes.object.isRequired,
  createTemplate: PropTypes.bool
}

class GuidebookForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stepperOpen: this.getDefaultStepperState(props),
      stepperEnabled: this.getDefaultStepperEnabled(props),
      visible: false,
      showUpgradeAlert: false,
      vcsConnected: false,
      showDetachConfirm: false,
      confirmDetachUid: null,
      loadedKey: null,
      servicesLoaded: false,
      servicesError: false,
      services: [],
      servicesDelta: null,
      allServicesLoaded: false,
      allServicesError: false,
      allServices: [],
      propertiesLoaded: false,
      propertiesError: false,
      properties: [],
      openRecsWizard: false,
      useWizard: this.getDefaultWizardState(props),
      wizardStep: this.getDefaultWizardStep()
    }
    this.renderNum = 0
    this.submitCb = () => {}
  }

  imageSizes = [
    [400, 400],
    [320, 240],
    [300, 300],
    [260, 260],
    [75, 75],
    [50, 50]
  ]

  componentWillUnmount() {
    this.mounted = false
  }
  async componentDidMount() {
    this.mounted = true
    const self = this
    const { dispatch } = this.props
    const pluralNames = [
      'templates',
      'guidebooks',
      'checkins',
      'checkouts',
      'directions',
      'approveddomains',
      'hostintros',
      'parkings',
      'themes',
      'wifis'
    ]

    if (window.location.search.includes('templateId')) {
      const templateId = window.location.search.split('?templateId=')[1]

      dispatch(newGuidebookWithTemplate(templateId))
    }

    pluralNames.forEach(function (pluralName) {
      dispatch(fetchItems(pluralName))
    })
    const user = currentUser()
    if (user.isPaidHost || user.canUpsell) {
      dispatch(checkStatus())
    }
    // If they are returning from creating/editing a card, get the details so we can select it and scroll to it.
    const hashParts = window.location.hash.split('#')
    if (hashParts.length >= 3) {
      self.wait_loaded = hashParts[1]
      // BA unfortunate that guidebook uses host_intro instead of hostintro
      const cardSingularName =
        hashParts[2] === 'hostintro' ? 'host_intro' : hashParts[2]
      self.edit_card_type = cardSingularName
      if (hashParts.length > 3) {
        self.edit_card_id = hashParts[3] === 'create' ? null : hashParts[3]
      }
      if (hashParts.length > 4) {
        self.edit_card_selected = hashParts[4] === 'true'
      }
    } else if (hashParts.length > 1 && hashParts[1] === 'publish') {
      this.requestAutosave = true
      this.handlePublished(this.props.edit_item, true)
    }

    checkVCSStatus().then((data) => {
      if (this.mounted) {
        // if the vcs check came back and this guidebook's ID is in the array of imported Gudiebooks
        // then we know that this one has been connected already.
        // not sure if this will be a string or integer
        if (
          data &&
          data.importedGuidebooks &&
          data.importedGuidebooks.length &&
          (data.importedGuidebooks.indexOf(parseInt(self.props.itemId, 10)) !==
            -1 ||
            data.importedGuidebooks.indexOf(self.props.itemId) !== -1)
        ) {
          this.setState({ vcsConnected: true })
        }
      }
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    const propsEqual = jsonEqual(this.props, nextProps)
    const stateEqual = jsonEqual(this.state, nextState)
    // if all of these things are the same, don't re-render
    if (propsEqual && stateEqual) {
      return false
    }
    if (this.state.wizardStep !== nextState.wizardStep) {
      this.loadWizardPrereqsByStep(nextState.wizardStep)
    }
    // don't re-render if nothing has loaded, and the item counts are the same
    if (stateEqual && this.isLoaded(this.props) === this.isLoaded(nextProps)) {
      const itemCounts = this.getItemCounts(this.props)
      const nextCounts = this.getItemCounts(nextProps)
      const itemCountsEqual = jsonEqual(itemCounts, nextCounts)
      const assignedCounts = this.getAssignedItemCounts(this.props.edit_item)
      const assignedCountsNext = this.getAssignedItemCounts(nextProps.edit_item)
      const assignedCountsEqual = jsonEqual(assignedCounts, assignedCountsNext)
      const imagesEqual =
        this.props.image === nextProps.image &&
        this.props.recs_only_image === nextProps.recs_only_image
      const addressEqual = jsonEqual(this.props.address, nextProps.address)
      const formEqual = jsonEqual(this.props.edit_form, nextProps.edit_form)
      const childrenEqual = jsonEqual(
        this.props.edit_children,
        nextProps.edit_children
      )
      if (
        addressEqual &&
        imagesEqual &&
        assignedCountsEqual &&
        itemCountsEqual &&
        formEqual &&
        childrenEqual
      ) {
        return false
      }
    }
    return true
  }

  componentWillReceiveProps(nextProps, nextState) {
    const { dispatch, edit_item, singularName } = nextProps
    const editName = 'edit_' + singularName
    const cardPluralName = this.wait_loaded
    if (
      cardPluralName &&
      nextProps[cardPluralName] &&
      nextProps[cardPluralName].data &&
      nextProps[cardPluralName].data.length
    ) {
      const cardSingularName = this.edit_card_type
      const cardId = this.edit_card_id
      const edit_card_selected = this.edit_card_selected
      this.wait_loaded = null
      if (['recommendation', 'information'].indexOf(cardSingularName) > -1) {
        // recs and information are multi select, so handle differently.
        let list = []
        if (cardId) {
          if (edit_card_selected) {
            const selected = edit_item[cardPluralName]
            const items = nextProps[cardPluralName].data
            const newItem = items.filter((item) => {
              return '' + item.id === cardId
            })
            const exists = selected.filter((item) => {
              return '' + item.id === cardId
            })
            list = exists.length ? selected : newItem.concat(selected)
          } else {
            const selected = edit_item[cardPluralName]
            list = selected.filter((item) => {
              return '' + item.id !== cardId
            })
          }
          dispatch(actions.change(editName + '.' + cardPluralName, list))
        }
      } else {
        if (cardId) {
          if (edit_card_selected) {
            dispatch(
              actions.change(editName + '.' + cardSingularName + '.id', cardId)
            )
          } else {
            dispatch(actions.change(editName + '.' + cardSingularName, {}))
          }
        }
      }
      const scrollToMarker =
        cardSingularName === 'guidebook'
          ? 'guidebook_step_' + cardId
          : cardSingularName
      document.querySelector(`#${scrollToMarker}`).scrollIntoView()
      // BA - it scrolls to the top of the page but the TopNav is in the way so scroll a bit further down
      window.scrollBy(0, -70)
    }
    // if we don't have a key in our 'edit_item' or the key is changing...
    if (
      (!this.state.loadedKey && nextProps.edit_item.key) ||
      (!this.state.loadedKey && nextProps.itemId === 'create') ||
      (this.state.loadedKey &&
        nextProps.edit_item.key &&
        this.state.loadedKey !== nextProps.edit_item.key)
    ) {
      let loadedKey = null
      if (nextProps.edit_item.key) {
        loadedKey = nextProps.edit_item.key
      } else if (nextProps.itemId === 'create') {
        loadedKey = 'create'
      }
      this.setState({ loadedKey: loadedKey }, () => {
        this.fetchExternalData(loadedKey)
      })
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const cardPluralName = this.wait_loaded
    if (
      cardPluralName === 'marketplaceItems' &&
      this.edit_card_id &&
      nextState.servicesLoaded &&
      nextState.allServicesLoaded &&
      (this.state.servicesLoaded || this.state.allServicesLoaded)
    ) {
      const newService = nextState.allServices.find(
        (item) => item.uid === this.edit_card_id
      )
      const updatedServices = Object.assign([], nextProps.services).concat([
        newService
      ])
      const updatedDelta = updatedServices.map((item, index) => {
        return { uid: item.uid, order: index }
      })
      // ok now make sure to save when we save the thing
      this.setState({ services: updatedServices, servicesDelta: updatedDelta })
    }
  }

  componentDidUpdate() {
    if (this.serverError) {
      const elementTop = this.serverError.offsetTop
      window.scrollTo(0, elementTop)
    }
  }

  isLoaded = (props) => {
    const {
      checkins,
      checkouts,
      directions,
      domains,
      hostintros,
      items,
      parkings,
      templates,
      themes,
      wifis
    } = props
    if (
      checkins.loaded &&
      checkouts.loaded &&
      directions.loaded &&
      domains.loaded &&
      hostintros.loaded &&
      items.loaded &&
      parkings.loaded &&
      templates.loaded &&
      themes.loaded &&
      wifis.loaded
    ) {
      return true
    }
    return false
  }

  fetchExternalData = (_key) => {
    const { edit_item } = this.props
    // if we have any properties connected, fetch them.
    if (edit_item && edit_item.properties && edit_item.properties.length > 0) {
      fetchProperties(edit_item.key).then((data) => {
        if (data.length) {
          this.setState({
            properties: data,
            propertiesLoaded: true,
            propertiesError: false
          })
        } else {
          this.setState({ propertiesLoaded: true, propertiesError: true })
        }
      })
    }
    // if we have any marketplace items connected, fetch them.
    if (
      edit_item &&
      edit_item.marketplace_items &&
      edit_item.marketplace_items.length > 0
    ) {
      fetchMarketplaceItems(edit_item.key).then((data) => {
        if (data.length) {
          this.setState({
            services: data,
            servicesLoaded: true,
            servicesError: false
          })
        } else {
          this.setState({ servicesLoaded: true, servicesError: true })
        }
      })
    } else {
      this.setState({ servicesLoaded: true, servicesError: false })
    }
    // fetch ALL services so we can assign them from here
    fetchProviders(
      /* success callback */
      (data) => {
        let providers = []
        let services = []
        if (data.length) {
          // take the returned data and split into list of providers and list of services.
          for (var i = 0, len = data.length; i < len; i++) {
            const item = Object.assign({}, data[i])
            if (item.services.length) {
              for (var j = 0, lenn = item.services.length; j < lenn; j++) {
                const service = Object.assign({}, item.services[j], {
                  providerName: item.companyName
                })
                services.push(service)
              }
            }
            providers.push(item)
          }
        }
        this.setState({
          allServicesLoaded: true,
          allServicesError: false,
          allServices: sortData(services, 'title')
        })
      },
      /* error callback */
      (err) => {
        this.setState({
          allServicesLoaded: true,
          allServicesError: true,
          allServices: []
        })
      }
    )
  }

  loadWizardPrereqsByStep = (step) => {
    if (wizardSteps[step].loadPrereqs) {
      const { dispatch } = this.props
      const stepModel = wizardSteps[step].model
      let newItem = blankEditStates[stepModel].data
      // add default message for House Rules step
      if (wizardSteps[step].stepName === 'rules') {
        newItem = Object.assign({}, newItem, {
          content:
            '<ul><li>No parties or events allowed.</li><li>No smoking allowed. </li><li>No pets allowed.</li><li>Suitable for toddlers and children under 12.</li><li>No unregistered guests allowed. </li><li>Please don’t eat or drink in the bedrooms. </li><li>Please respect the noise curfew. </li><li>Please turn off the AC when you go out.</li><li>Please respect check-in and checkout times. </li><li>Please take extra care of your keys. Lost keys incur a replacement fee.</li><li>Please take care of the furnishings. You have to pay for damages that exceed the security deposit. </li><li>Please don’t rearrange the furniture.</li><li>Please do your dishes.</li><li>Please take the trash out before you leave.</li><li>No illegal substances allowed on the premises.</li></ul><p><br /></p>'
        })
      }
      if (wizardSteps[step].stepName === 'intro') {
        newItem = Object.assign({}, newItem, {
          host_intro:
            'Dear Guest,<br/>Welcome to (lodging name). We are thrilled to welcome you to your home away from home.<br/>We want you to have complete peace of mind when staying with us. Please find all property information and guest services in this personalized guidebook for your stay. We are delighted to have you as our guest and are just a message away should you have any additional questions.<br/>(Signature)'
        })
      }
      // if (wizardSteps[step].stepName === "directions") {
      //   newItem = Object.assign({}, newItem, {additional_directions: "It's 2024.  use gps."});
      // }
      dispatch(actions.load(`edit_${stepModel}`, newItem))
    }
  }

  generateRecsAndNext = () => {
    // run thingy to generate recs and then move to next step
    generateRecs(this.props.itemId)
    this.skipStep()
  }

  handleMarketplaceSelectionChange = (selection) => {
    let newSelection = []
    // strip everything but the id out of each item
    if (selection.length) {
      newSelection = selection.map((item, index) => {
        return { uid: item.uid, order: index }
      })
    }
    // ok now make sure to save when we save the thing
    this.setState({ services: selection, servicesDelta: newSelection })
  }

  handleDomainChange = (selection) => {
    const { dispatch, domains, singularName } = this.props
    const editName = 'edit_' + singularName
    // find domain in lists
    if (selection > 0) {
      const selectedDomain = domains.data.find(
        (domain) => domain.id === selection
      )
      dispatch(actions.change(editName + '.domain', selectedDomain))
    }
  }

  handleMarketplaceSort = (orderedItems) => {
    let newOrder = []
    if (orderedItems.length) {
      newOrder = orderedItems.map((item, index) => {
        return { uid: item.uid, order: index }
      })
    }
    this.setState({ services: orderedItems, servicesDelta: newOrder })
  }

  getItemCounts = (props) => {
    const {
      checkins,
      checkouts,
      directions,
      domains,
      hostintros,
      informations,
      items,
      parkings,
      recommendations,
      templates,
      themes,
      wifis
    } = props
    const counts = {
      checkins: checkins.data.length,
      checkouts: checkouts.data.length,
      directions: directions.data.length,
      domains: domains.data.length,
      hostintros: hostintros.data.length,
      items: items.data.length,
      informations: informations.data.length,
      parkings: parkings.data.length,
      recommendations: recommendations.data.length,
      templates: templates.data.length,
      themes: themes.data.length,
      wifis: wifis.data.length
    }
    return counts
  }

  getAssignedItemCounts = (guidebook) => {
    const { informations, recommendations } = guidebook
    const counts = {
      informations: informations.length,
      recommendations: recommendations.length
    }
    return counts
  }

  handleEnter = (e) => {
    e.preventDefault()
  }

  handleStepClick = (step) => {
    const currentVal = this.state.stepperOpen[step]
    let newStepperState = Object.assign({}, this.state.stepperOpen)
    newStepperState[step] = !currentVal
    this.setState({ stepperOpen: newStepperState })
  }

  getDefaultWizardState = (props) => {
    if (props.singularName === 'template') {
      return false
    } else if (
      props.edit_item &&
      props.edit_item.template &&
      props.edit_item.template.id
    ) {
      return false
    } else if (props.itemId === 'create') {
      return null
    } else {
      const { cookies, itemId } = props
      const cookieVal = cookies.get(`hf_wizard_enabled_${itemId}`)
      // if we have a match then groovy
      if (typeof cookieVal !== 'undefined') return cookieVal
      // there's a case where after creation we need to update the cookies
      const fbUseWizardVal = cookies.get(`hf_wizard_enabled_create`)
      if (typeof fbUseWizardVal !== 'undefined') {
        // delete this cookie, then set a new one
        const fbWizardStepVal = cookies.get(`hf_wizard_step_create`)
        this.cleanupWizardCookies('create')
        this.saveNextStepCookie(fbWizardStepVal)
        return fbWizardStepVal
      }
      return false
    }
  }
  getDefaultWizardStep = () => {
    // see if we have an hf-wizard-step cookie
    const { cookies, itemId } = this.props
    const cookieVal = cookies.get(`hf_wizard_step_${itemId}`)
    if (typeof cookieVal !== 'undefined') {
      const wizardStep = parseInt(cookieVal, 10)
      this.loadWizardPrereqsByStep(wizardStep)
      return wizardStep
    }
    return 0
  }

  previewGuidebook = (value) => {
    const { itemId, edit_item } = this.props
    this.cleanupWizardCookies(itemId)
    // need to make sure stepper is open for recs
    let newStepperState = Object.assign({}, this.state.stepperOpen)
    newStepperState[3] = true

    this.setState(
      { stepperOpen: newStepperState, useWizard: false, wizardStep: 0 },
      () => {
        let path = `/${edit_item.key}`
        const domain =
          edit_item.domain && edit_item.domain.status === 'approved'
            ? edit_item.domain.domain
            : null
        const previewUrl = domain ? `https://${domain}${path}` : path
        window.open(previewUrl, 'newExternalTab')
        window.focus()
        // at the same time we want to reload the current window
        const refreshUrl = `/host/guidebooks/${itemId}#recommendations#recommendation`
        window.location.href = refreshUrl
        window.location.reload()
      }
    )
  }

  cleanupWizardCookies = (itemId) => {
    const { cookies } = this.props
    const cookieOptions = {
      path: '/',
      expires: 0,
      sameSite: 'strict'
    }
    cookies.remove(`hf_wizard_step_${itemId}`, cookieOptions)
    cookies.remove(`hf_wizard_enabled_${itemId}`, cookieOptions)
  }

  getDefaultStepperState = (props) => {
    let openStates = {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false
    }
    if (props.itemId === 'create') {
      openStates[1] = true
    }
    // see if we're coming back from editing a card
    const hashParts = window.location.hash.split('#')
    if (hashParts && hashParts[2]) {
      const returnSection = hashParts[2]
      switch (returnSection) {
        case 'checkin':
        case 'directions':
        case 'parking':
        case 'wifi':
        case 'checkout':
          openStates[2] = true
          break
        case 'information':
        case 'recommendation':
        case 'marketplaceItem':
          openStates[3] = true
          break
        case 'hostintro':
        case 'theme':
        case 'domain':
          openStates[4] = true
          break
        default:
        // do nothing
      }
    }
    return openStates
  }

  getDefaultStepperEnabled = (props) => {
    let enabledStates = {
      1: true,
      2: true,
      3: true,
      4: true,
      5: true
    }
    if (props.itemId === 'create') {
      enabledStates[2] = false
      enabledStates[3] = false
      enabledStates[4] = false
      enabledStates[5] = false
    }
    return enabledStates
  }

  createCard = (cardSingularName, cardPluralName) => {
    const { dispatch, itemId, pluralName, singularName, copy } = this.props
    const editName = 'edit_' + singularName
    // BA. make sure that we don't reload/refresh the guidebook when going off to edit a card
    dispatch(actions.change(editName + '.noReload', true))
    const id = copy ? 'create' : itemId // if they are copying, this should operate the same as creating a new guidebook, as the values are already copied when we get here.
    dispatch(
      replace(
        '/host/' + pluralName + '/' + id + '/' + cardPluralName + '/create'
      )
    )
  }

  editCard = (cardSingularName, cardPluralName) => {
    const { dispatch, itemId, edit_item, pluralName, singularName } = this.props
    const editName = 'edit_' + singularName
    const cardId = edit_item[cardSingularName].id
    // BA. make sure that we don't reload/refresh the guidebook when going off to edit a card
    dispatch(actions.change(editName + '.noReload', true))
    dispatch(
      replace(
        '/host/' +
          pluralName +
          '/' +
          itemId +
          '/' +
          cardPluralName +
          '/' +
          cardId +
          '?guidebookSelected=true'
      )
    )
  }

  imageUploaded = (uploadResult, imageField) => {
    const { dispatch, singularName } = this.props
    const editName = 'edit_' + singularName
    if (uploadResult.filesUploaded && uploadResult.filesUploaded.length > 0) {
      const file = uploadResult.filesUploaded[0]
      const filestackUrl = file.url
      fixRotation(filestackUrl).then((data) => {
        if (data.url && data.url.length > 0) {
          preloadImages(data.url, this.imageSizes)
          dispatch(actions.change(editName + '.' + imageField, data.url))
        }
      })
    }
  }

  cardSelected = (model) => {
    return model && model.id && model.id !== 0
  }

  noDomainOnDraft = (val) => {
    if (val && this.props.edit_form.is_published.value) {
      return true
    } else {
      return false
    }
  }

  customUrlIsValid = (val) => {
    if (!val) {
      if (this.props.itemId === 'create') {
        return true
      } else {
        return false
      }
    }
    if (!isValidUrlString(val)) return false
    // TODO add a synchronous api call here or something.
    return true
  }

  formHasErrors = (formErrors) => {
    const edit_form = this.props.edit_form
    if (this.props.hasErrored) return false
    if (edit_form.$form.touched) {
      for (var field in formErrors) {
        if (field.substring(0, 7) === 'address') {
          var subField = field.split('.').pop()
          if (
            edit_form.address[subField] &&
            !edit_form.address[subField].valid
          ) {
            return false
          }
        } else {
          if (edit_form[field] && !edit_form[field].valid) {
            return false
          }
        }
      }
    }
    return true
  }

  showDialog = () => {
    this.setState({ visible: true })
  }

  hideDialog = () => {
    this.setState({ visible: false })
  }

  showUpgradeDialog = () => {
    this.setState({ showUpgradeAlert: true })
  }

  hideUpgradeDialog = () => {
    this.setState({ showUpgradeAlert: false })
  }

  activate = () => {
    this.hideDialog()
    window.location.href =
      process.env.REACT_APP_WORDPRESS_URL + '/upgrade-power'
  }

  handleContinue = (item, step) => {
    const { dispatch, singularName } = this.props
    this.requestAutosave = true
    dispatch(actions.resetValidity('edit_' + singularName))
    dispatch(actions.setTouched('edit_' + singularName))
    dispatch(actions.submit('edit_' + singularName))
  }

  handleSaveTemplate = (item) => {
    const { dispatch, singularName } = this.props
    dispatch(actions.resetValidity('edit_' + singularName))
    dispatch(actions.setTouched('edit_' + singularName))
    dispatch(actions.submit('edit_' + singularName))
  }

  handlePublished = (item, is_published) => {
    // for debug purposes
    const ignoreLimits = false
    const { items, dispatch, singularName } = this.props
    // get count of published guidebooks (not templates)
    const currentCount = items.data
      ? items.data.filter(
          (item) => item.is_template === false && item.is_published === true
        ).length
      : 0
    const user = currentUser()
    // verify user has available published guidebooks
    if (
      currentCount >= user.guidebookLimit &&
      is_published &&
      !item.is_published &&
      !ignoreLimits
    ) {
      this.showUpgradeDialog()
    } else {
      dispatch(actions.resetValidity('edit_' + singularName))
      dispatch(actions.setTouched('edit_' + singularName))
      dispatch(
        actions.change('edit_' + singularName + '.is_published', is_published)
      )
      dispatch(actions.submit('edit_' + singularName))
    }
  }

  handleChangeRecsOnlyEnabled = (value) => {
    const { singularName, dispatch } = this.props
    const editName = 'edit_' + singularName
    dispatch(actions.change(editName + '.recs_only_enabled', value))
    // if we're turning this on, autosave so we can generate a key on the server if needed
    if (value === true) {
      this.handleContinue(editName)
    }
  }

  handleChangeCreateTokens = (value) => {
    const { singularName, dispatch, edit_item } = this.props
    const editName = 'edit_' + singularName
    // fire an ajax request to verify and setup any triggers required for this
    this.submitCb = () => {
      autoCreateTokens(edit_item.id, value)
    }
    // change the model itself to set this flag on the guidebook
    dispatch(actions.change(editName + '.create_tokens', value))
    this.handleContinue(editName)
  }

  resetSubmitCallback = () => {
    this.submitCb = () => {}
  }

  handleDefaultChange = (value) => {
    const { singularName, dispatch } = this.props
    const editName = 'edit_' + singularName
    dispatch(actions.change(editName + '.default_path', value))
  }

  handleSubmit = (item) => {
    const { dispatch, edit_item, pluralName, singularName } = this.props
    const id = edit_item.id

    if (this.requestAutosave === true) {
      this.requestAutosave = false
      const guidebook = this.stripCardContent(item)
      dispatch(
        autoSaveItem(pluralName, singularName, id, guidebook, id, this.submitCb)
      )
    } else {
      const addAnother = this.addAnother
      this.addAnother = false
      const guidebook = this.stripCardContent(item)
      dispatch(
        saveItem(
          pluralName,
          singularName,
          id,
          guidebook,
          addAnother,
          null,
          this.submitCb
        )
      )
    }
  }

  stripCardContent = (guidebook) => {
    const recIds = guidebook.recommendations
      ? guidebook.recommendations.map((item) => {
          return { id: item.id }
        })
      : []
    const infoIds = guidebook.informations
      ? guidebook.informations.map((item) => {
          return { id: item.id }
        })
      : []
    // add in servicesDelta so we can add/update attached marketplace items
    const services = this.state.servicesDelta
    const result = Object.assign({}, guidebook, {
      recommendations: recIds,
      informations: infoIds,
      services: services
    })
    if (this.ignoreRecs === true) {
      this.ignoreRecs = false
      delete result.recommendations
    }
    return result
  }

  saveClick = (e) => {
    const { dispatch, singularName } = this.props
    dispatch(actions.submit('edit_' + singularName))
  }

  cancelClick = (e) => {
    const { dispatch, singularName, pluralName } = this.props
    dispatch(apiError(false, singularName))
    dispatch(replace('/host/' + pluralName))
  }

  renderUpgradeDialog = () => {
    const buttonContainerStyle = { marginTop: '12px' }
    return (
      <DialogContainer
        id="upgradeAlert"
        visible={this.state.showUpgradeAlert}
        title="Upgrade"
        onHide={this.hideUpgradeDialog}
        aria-label="Upgrade to access feature alert"
      >
        <div className="md-grid md-grid--no-spacing">
          <div className="md-cell md-cell--12">
            You have reached your limit of published guidebooks. You can still
            save this guidebook as a draft to keep your changes.
            <br />
            <br />
            To publish this guidebook, you can manage your account to add more
            guidebooks, or you can open a different guidebook that is already
            published and save it as a draft.
          </div>
          <div
            className="md-cell md-cell--12 md-text-center"
            style={buttonContainerStyle}
          >
            <Button flat onClick={this.hideUpgradeDialog}>
              Ok
            </Button>
          </div>
        </div>
      </DialogContainer>
    )
  }

  renderServerErrors = (focus) => {
    const self = this
    const focusOnError = focus || false
    const { hasErrored, errors } = this.props
    let errorMsg
    if (hasErrored) {
      if (errors.account) {
        errorMsg = errors.account[0]
      }
      if (errors.key) {
        errorMsg = 'This custom URL ' + errors.key[0]
      }
      if (errors.recs_only_key) {
        errorMsg =
          'This custom recommendations-only URL ' + errors.recs_only_key[0]
      }
    }
    if (!errorMsg) return null
    return (
      <div
        className="md-text-field-message hf-error"
        tabIndex="-1"
        ref={(element) => {
          if (focusOnError) {
            self.serverError = element
          }
        }}
      >
        <span>{errorMsg}</span>
      </div>
    )
  }

  handleVCSClick = () => {
    // track this click!
    const user = currentUser()
    const gb = this.props.edit_item
    if (user.id === gb.host_user_id) {
      const vcs_base = `${process.env.REACT_APP_VCS_URL}/registration?affiliate=429116`
      const vcs_url = `${vcs_base}&id=${user.id}&guidebook_id=${gb.id}&guidebook_key=${gb.key}`

      // OLD SEGMENT CODE
      // let eventData = getHostIdAndKey(this.props.edit_item);
      // eventData.category = 'Host';
      // trackEvent('activateVCS', eventData, {}, function(){
      window.open(vcs_url)
      // });
    }
  }

  handleVCSRedirect = () => {
    const user = currentUser()
    const gb = this.props.edit_item
    if (user.id === gb.host_user_id) {
      const vcs_base = `${process.env.REACT_APP_VCS_URL}/properties/redirect-to-content`
      const vcs_url = `${vcs_base}?guidebook_id=${gb.id}`
      window.open(vcs_url)
    }
  }

  handleVCSImport = () => {
    const user = currentUser()
    const gb = this.props.edit_item
    if (user.id === gb.host_user_id) {
      reImportGuidebook(gb.id, gb.key).then((data) => {
        if (data.error) {
          this.props.dispatch(
            addToast('Error importing guidebook: ' + data.error)
          )
        } else if (data === '') {
          this.props.dispatch(
            addToast('Guidebook reimport has been triggered.')
          )
        }
      })
    }
  }

  renderLoader = () => {
    const loaderStyle = { height: 'calc(100vh - 200px)' }
    return (
      <div className="md-grid md-grid--no-spacing" style={loaderStyle}>
        <div className="md-cell md-cell--12 md-text-center">
          <LinearProgress id="status_check" />
          <div>Loading Cards...</div>
        </div>
      </div>
    )
  }

  addDefaultPaths = (currentPaths, currentTabs, item) => {
    const self = this
    // if there are house manual tabs and we don't have that path yet...
    if (
      item.informations.length &&
      currentTabs.indexOf('house_manual') === -1
    ) {
      currentTabs.push('house_manual')
      currentPaths.push({ value: '/house_manual', label: 'House Manual' })
    }
    // go through recommendations and add each category that we don't already have
    if (item.recommendations.length) {
      for (let i = 0, len = item.recommendations.length; i < len; i++) {
        const category = item.recommendations[i].category
        const cat_idx = category.name.toLowerCase().replace(/ /g, '_')
        const cat_label = `${category.name.charAt(0).toUpperCase()}${category.name.slice(1)}`
        if (currentTabs.indexOf(cat_idx) === -1) {
          currentTabs.push(cat_idx)
          currentPaths.push({
            value: `/recommendations/${cat_idx}`,
            label: cat_label
          })
        }
      }
      // add "all recommendations" path if not already present
      if (currentTabs.indexOf('all_recs') === -1) {
        currentTabs.push('all_recs')
        currentPaths.push({
          value: `/recommendations/all`,
          label: 'All Recommendations'
        })
      }
    }
    if (item.informations.length) {
      for (let j = 0, len = item.informations.length; j < len; j++) {
        const category = item.informations[j].category
        if (category) {
          const cat_idx = category.name.toLowerCase().replace(/ /g, '_')
          const cat_label = `${category.name.charAt(0).toUpperCase()}${category.name.slice(1)}`
          if (currentTabs.indexOf(cat_idx) === -1) {
            currentTabs.push(cat_idx)
            currentPaths.push({ value: `/${cat_idx}`, label: cat_label })
          }
        }
      }
    }
    // if our item has a template, recurse into the template and do the same there
    if (
      item.template &&
      item.template.id &&
      this.props.templates.data &&
      this.props.templates.data.length
    ) {
      // go thorugh our list of templates in memory
      for (let t = 0, tlen = self.props.templates.data.length; t < tlen; t++) {
        // look for the actual template that matches the id
        if (self.props.templates.data[t].id === item.template.id) {
          // found it -  grab the data and recurse
          const template = this.props.templates.data[t]
          const recurseData = {
            id: template.id,
            informations: template.informations,
            recommendations: template.recommendations,
            template: template.template
          }
          // call this same function with the template data
          self.addDefaultPaths(currentPaths, currentTabs, recurseData)
          break
        }
      }
    }
  }

  confirmDetachProperty = (property_uid, e) => {
    e.stopPropagation()
    this.setState({ showDetachConfirm: true, confirmDetachUid: property_uid })
  }

  closeDetachDialog = () => {
    this.setState({ showDetachConfirm: false, confirmDetachUid: null })
  }

  viewProperty = (link) => {
    window.open(link, 'newExternalTab')
  }

  detachProperty = () => {
    const { dispatch, itemId } = this.props
    const { confirmDetachUid } = this.state
    this.closeDetachDialog()
    detachPropertyFromGuidebook(confirmDetachUid, itemId).then((data) => {
      dispatch(fetchItem('guidebooks', 'guidebook', itemId))
    })
  }

  renderDetachDialog = () => {
    return (
      <DialogContainer
        id="simpleDialogExample"
        aria-label="Confirm delete dialog"
        visible={this.state.showDetachConfirm}
        onHide={this.closeDetachDialog}
        actions={[
          {
            onClick: this.closeDetachDialog,
            primary: true,
            label: 'Cancel'
          },
          {
            onClick: this.detachProperty,
            primary: true,
            label: 'Detach'
          }
        ]}
      >
        <p id="detachPropertyDescription" className="md-color--secondary-text">
          Confirm that you would like to detach this property from the guidebook
        </p>
      </DialogContainer>
    )
  }

  renderConnectedProperties = () => {
    const self = this
    const { edit_item } = this.props

    if (
      !edit_item ||
      !edit_item.properties ||
      edit_item.properties.length === 0
    )
      return null

    let propertyContent = null
    if (!this.state.propertiesLoaded) {
      propertyContent = (
        <div className="md-cell md-cell--12 md-text-center">
          <LinearProgress id="status_check" />
          <div>Loading Properties...</div>
        </div>
      )
    } else if (this.state.propertiesError) {
      propertyContent = (
        <div className="md-cell md-cell--12 md-text-center">
          <div>
            <FontIcon className="error" key="error">
              error_outline
            </FontIcon>
          </div>
          Unfortunately, something went wrong fetching connected properties
          <br />
          Please try reloading the page, or trying again in a few minutes.
          <br />
          <br />
          If the issue persists, please contact{' '}
          <a href="mailto:support@hostfully.com">support@hostfully.com</a>
        </div>
      )
    } else {
      const copyUid = (e) => {
        this.props.dispatch(
          addToast('The property Uid has been copied to the clipboard.')
        )
      }
      const connectedProperties = this.state.properties.map((property) => {
        const image = property.picture
          ? resizeImage(property.picture, 50, 50, true)
          : null
        const uidSpan = (
          <span>
            <TooltipSpan
              tooltipLabel="Click to copy property uid to clipboard"
              tooltipPosition="right"
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <CopyToClipboard
                className="show-pointer hf-copy-link"
                text={property.uid}
                onCopy={(e) => {
                  copyUid(e)
                }}
              >
                <span>{property.uid}</span>
              </CopyToClipboard>
            </TooltipSpan>
          </span>
        )
        return (
          <ListItem
            key={property.uid}
            onClick={() => {
              self.viewProperty(property.propertyURL)
            }}
            className="hf-list-item"
            leftAvatar={<Avatar src={image} role="presentation" />}
            primaryText={property.name}
            secondaryText={uidSpan}
            rightIcon={
              <TooltipFontIcon
                tooltipLabel="Detach Property"
                tooltipPosition="top"
                onClick={(e) => {
                  self.confirmDetachProperty(property.uid, e)
                }}
              >
                link_off
              </TooltipFontIcon>
            }
          />
        )
      })

      propertyContent = (
        <div className="md-cell md-cell--12">
          <List>{connectedProperties}</List>
          {this.renderDetachDialog()}
        </div>
      )
    }

    return (
      <Card className="hf-wider-selection-control-container">
        <CardTitle
          avatar={
            <Avatar
              icon={<FontIcon>link</FontIcon>}
              role="presentation"
              suffix="hfamber"
            />
          }
          title="Connected PMP Properties"
        />
        <CardText>
          <div className="md-grid md-grid--no-spacing">{propertyContent}</div>
        </CardText>
      </Card>
    )
  }

  renderSecureSwitch = () => {
    const user = currentUser()
    const { edit_item, singularName } = this.props
    if (singularName !== 'guidebook') return null

    if (!user.canUseSecurity) {
      return (
        <Card className="hf-wider-selection-control-container">
          <CardTitle
            avatar={
              <Avatar
                icon={<FontIcon>security</FontIcon>}
                role="presentation"
                suffix="hfbluegrey"
              />
            }
            title="Secure Access"
          />
          <CardText>
            <div className="md-grid md-grid--no-spacing">
              <div key="securityspacer" className="md-cell md-cell--1"></div>
              <div
                key="securityswitch"
                className="md-cell md-cell--11 md-cell--7-tablet md-cell--3-phone"
              >
                <h2>Do you want to restrict access to this guidebook?</h2>
                <p>
                  This will allow only guests using a secure link to view your
                  guidebook.
                  <br />
                  To enable access to this feature, you must be a paid
                  subscriber. Click below to upgrade your account.
                </p>
                <div className="md-grid md-grid--no-spacing">
                  <Button
                    onClick={this.showDialog}
                    iconChildren="account_circle"
                    flat
                    primary
                  >
                    Activate
                  </Button>
                </div>
              </div>
            </div>
          </CardText>
        </Card>
      )
    } else {
      const securitySwitch = [
        <div key="securityspacer" className="md-cell md-cell--1"></div>,
        <div
          key="securityswitch"
          className="md-cell md-cell--11 md-cell--7-tablet md-cell--3-phone"
        >
          <h2>Do you want to restrict access to this guidebook?</h2>
          <p>
            This will allow only guests using a secure link to view your
            guidebook. The normal guidebook url will not work, though you will
            still be able to preview your guidebook as long as you are logged
            in.
            <br />
            <a className="hf-grey-link" target="_blank" href="/host/tokens">
              You can create and edit secure links here
            </a>
          </p>
          <div className="md-grid md-grid--no-spacing">
            <div className="md-cell md-cell--1 md-cell--1-phone">
              <p className="md-text md-text-right hf-switch-left">No</p>
            </div>
            <div className="md-cell md-cell--1 md-cell--1-phone">
              <HfSwitchForm
                name="secure_access_only"
                editModel={'edit_guidebook'}
                model="secure_access_only"
                checked={edit_item.secure_access_only}
                label="Yes"
                labelBefore
              />
            </div>
          </div>
        </div>
      ]

      const autoCreateLinksSwitch =
        edit_item &&
        edit_item.properties &&
        this.state.properties &&
        this.state.properties.length
          ? [
              <div key="autocreatespacer" className="md-cell md-cell--1"></div>,
              <div
                key="autocreateswitch"
                className="md-cell md-cell--11 md-cell--7-tablet md-cell--3-phone"
              >
                <h2>
                  Do you want to automatically create secure links for this
                  guidebook?
                </h2>
                <p>
                  When the Property Management Platform receives new
                  reservations for a property connected to this guidebook, the
                  system will automatically create new secure links for those
                  reservations with as many guest details as possible.
                </p>
                <div className="md-grid md-grid--no-spacing">
                  <div className="md-cell md-cell--1 md-cell--1-phone">
                    <p className="md-text md-text-right hf-switch-left">No</p>
                  </div>
                  <div className="md-cell md-cell--1 md-cell--1-phone">
                    <Switch
                      id="create_tokens"
                      name="create_tokens"
                      checked={edit_item.create_tokens}
                      onChange={this.handleChangeCreateTokens}
                      label="Yes"
                    />
                  </div>
                </div>
              </div>
            ]
          : null

      return (
        <Card className="hf-wider-selection-control-container">
          <CardTitle
            avatar={
              <Avatar
                icon={<FontIcon>security</FontIcon>}
                role="presentation"
                suffix="hfbluegrey"
              />
            }
            title="Secure Access"
          />
          <CardText>
            <div className="md-grid md-grid--no-spacing">
              {securitySwitch}
              {autoCreateLinksSwitch}
            </div>
          </CardText>
        </Card>
      )
    }
  }

  toggleUseWizard = (answer) => {
    const setStep = answer === true ? 1 : 0
    this.setState({ useWizard: answer, wizardStep: setStep })
  }

  skipStep = () => {
    const { singularName } = this.props
    const currentStep = this.state.wizardStep
    const editName = `edit_${singularName}`

    if (currentStep === 8 && this.props.items.data.length === 0) {
      this.requestAutosave = true
      this.handlePublished(editName, true)
    }

    this.saveNextStepCookie(currentStep + 1)
    this.setState({ wizardStep: currentStep + 1 })
  }

  saveNextStepCookie = (nextStep) => {
    const { cookies, itemId } = this.props
    const cookieOptions = {
      path: '/',
      expires: 0,
      sameSite: 'strict'
    }
    cookies.set(`hf_wizard_enabled_${itemId}`, true, cookieOptions)
    cookies.set(`hf_wizard_step_${itemId}`, nextStep, cookieOptions)
  }

  saveInitialAndReloadWizard = () => {
    const { dispatch, singularName } = this.props
    const editName = 'edit_' + singularName
    dispatch(actions.change('edit_' + singularName + '.used_quick_start', true))
    // drop a cookie with next step for wizard
    this.saveNextStepCookie(2)

    this.handleContinue(editName)
  }

  nextStep = (item, staticData, close, publish) => {
    const { dispatch, edit_item, singularName } = this.props
    const currentStep = this.state.wizardStep
    const { model, labelField, pluralName, title } = wizardSteps[currentStep]

    // first we want to save the item itself, for most models we need a label
    let labelData = {}
    const label = `${edit_item.name} ${title}`
    labelData[labelField] = label
    if (typeof staticData !== 'undefined') {
      labelData = { ...labelData, ...staticData }
    }
    // for house manual and recommendations we need to add in the connection to the guidebook
    if (['information', 'recommendation'].indexOf(model) !== -1) {
      labelData['guidebooks'] = [{ id: this.props.itemId }]
    }
    // now update the item object (since it's immutable we gotta make a new object)
    const newItem = Object.assign({}, item, labelData)
    // if we're not editing, use 'create' as id
    const itemId = typeof item.id === 'undefined' ? 'create' : item.id
    // don't add another and don't auto-redirect
    const addAnother = false
    const keepEditing = true
    const editName = `edit_${singularName}`
    // set up success CB
    // once we save the item itself, we want to also save the GB
    const successCb = (response) => {
      if (response.data && response.data.id) {
        dispatch(fetchItems(pluralName), (response) => {})
        // if it's not information or recommendation, save to the guidebook
        if (['information', 'recommendation'].indexOf(model) === -1) {
          if (model === 'hostintro') {
            dispatch(actions.change(editName + '.host_intro', response.data))
          } else {
            dispatch(actions.change(editName + '.' + model, response.data))
          }
        }
        this.ignoreRecs = true
        this.handleContinue(editName)
        if (close) {
          this.cleanupWizardCookies(this.props.itemId)
          this.setState({ useWizard: false })
        } else if (publish && this.props.items.data.length === 0) {
          this.requestAutosave = true
          this.handlePublished(editName, true)
          this.saveNextStepCookie(currentStep + 1)
          this.setState({ wizardStep: currentStep + 1 })
        } else {
          this.saveNextStepCookie(currentStep + 1)
          this.setState({ wizardStep: currentStep + 1 })
        }
      } else {
        dispatch(addToast('Hmm. something went wrong'))
      }
    }
    // go!
    dispatch(
      saveItem(
        pluralName,
        model,
        itemId,
        newItem,
        addAnother,
        edit_item,
        successCb,
        keepEditing
      )
    )
  }

  closeWizard = () => {
    const { itemId } = this.props
    this.cleanupWizardCookies(itemId)
    this.setState({ useWizard: false }, () => {
      const refreshUrl = `/host/guidebooks/${itemId}#recommendations#recommendation`
      window.location.href = refreshUrl
      window.location.reload()
    })
  }

  saveAndCloseWizard = () => {
    const { singularName, itemId } = this.props
    const editName = 'edit_' + singularName
    this.cleanupWizardCookies(itemId)
    this.handleContinue(editName)
    this.setState({ useWizard: false })
  }

  render() {
    if (!this.isLoaded(this.props)) {
      return this.renderLoader()
    }

    const {
      templates,
      singularName,
      edit_item,
      checkins,
      checkouts,
      directions
    } = this.props
    const {
      domains,
      hostintros,
      parkings,
      themes,
      wifis,
      image,
      address,
      recs_only_image
    } = this.props
    const editName = 'edit_' + singularName
    const { visible } = this.state
    const user = currentUser()
    const self = this

    const formatMessage = this.props.intl.formatMessage
    const domain =
      edit_item.domain && edit_item.domain.status === 'approved'
        ? edit_item.domain.domain
        : null

    const preview_image = image ? (
      <Media aspectRatio="4-3">
        <img src={resizeImage(image, 320, 240, true)} alt="" />
      </Media>
    ) : (
      <Media aspectRatio="4-3">
        <img
          src="https://storage.googleapis.com/hostfully-wp-1/blank-state-images.png"
          alt=""
        />
      </Media>
    )

    const preview_recs_only_image = recs_only_image ? (
      <Media aspectRatio="4-3">
        <img src={resizeImage(recs_only_image, 320, 240, true)} alt="" />
      </Media>
    ) : (
      <Media aspectRatio="4-3">
        <img
          src="https://storage.googleapis.com/hostfully-wp-1/blank-state-images.png"
          alt=""
        />
      </Media>
    )

    const filestackOptions = {
      accept: 'image/*',
      maxFiles: 1,
      fromSources: ['local_file_system', 'url', 'imagesearch'],
      storeTo: {
        location: 'gcs'
      },
      imageMax: [1600, 1200],
      transformations: {
        crop: {
          aspectRatio: 4 / 3,
          force: true
        },
        rotate: true
      }
    }
    const formErrors = []
    const errorMessages = {
      formwide: {
        valid: 'Oops! Unable to save:'
      },
      key: {
        limit_reached: 'You have reached your ' + singularName + ' limit',
        invalidKey:
          'Invalid character - use only letters, numbers, hyphens, and underscores',
        numericKey: 'Please enter a custom key that is not entirely numeric',
        save_error: 'Oops! Unable to save'
      },
      domain: {
        no_draft: 'You cannot use a custom domain on a draft guidebook'
      },
      recs_only_key: {
        invalidKey:
          'Invalid character - use only letters, numbers, hyphens, and underscores',
        numericKey:
          'Please enter a recommendations only key that is not entirely numeric'
      },
      name: {
        required: 'Please provide a name for your ' + singularName
      },
      street: {
        required: 'Please provide a street address'
      },
      locality: {
        required: 'Please provide a city / suburb'
      },
      country_code: {
        required: 'Please provide a country'
      },
      lat: {
        required: 'Please provide a map location'
      }
    }
    formErrors['formwide'] = (
      <Errors
        model={editName}
        messages={errorMessages.formwide}
        wrapper="h2"
        className="md-text-field-message title hf-error"
        show={(form) => form.submitFailed}
      />
    )
    formErrors['key'] = (
      <Errors
        model=".key"
        messages={errorMessages.key}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['recs_only_key'] = (
      <Errors
        model=".recs_only_key"
        messages={errorMessages.recs_only_key}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['name'] = (
      <Errors
        model=".name"
        messages={errorMessages.name}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['domain.id'] = (
      <Errors
        model=".domain.id"
        messages={errorMessages.domain}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['address.street'] = (
      <Errors
        model=".address.street"
        messages={errorMessages.street}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['address.locality'] = (
      <Errors
        model=".address.locality"
        messages={errorMessages.locality}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['address.country_code'] = (
      <Errors
        model=".address.country_code"
        messages={errorMessages.country_code}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )
    formErrors['address.lat'] = (
      <Errors
        model=".address.lat"
        messages={errorMessages.lat}
        className="md-text-field-message hf-error"
        show={(field) => field.touched && !field.focus}
      />
    )

    const filestackKey = process.env.REACT_APP_FILESTACK_KEY
    const gb_address = address || {}

    const actions = []
    actions.push(
      <Button flat primary onClick={this.hideDialog}>
        Cancel
      </Button>
    )
    actions.push(
      <Button flat primary onClick={this.activate}>
        Continue
      </Button>
    )

    const disableCustomUrl =
      user.canCustomizeUrls && singularName === 'guidebook' ? false : true
    const customUrlWrapperClass = disableCustomUrl
      ? 'md-cell md-cell--9 md-cell--5-tablet md-cell--middle hf-disabled-input'
      : 'md-cell md-cell--9 md-cell--5-tablet md-cell--middle'
    const customUrlCta = disableCustomUrl ? (
      singularName === 'guidebook' ? (
        <Button
          onClick={this.showDialog}
          iconChildren="account_circle"
          flat
          primary
        >
          Activate
        </Button>
      ) : (
        <Button iconChildren="do_not_disturb" flat primary>
          GB Only
        </Button>
      )
    ) : null

    const disableInput = disableCustomUrl ? true : false
    const customUrlIconClass = disableCustomUrl
      ? 'md-inline-block md-btn md-btn--icon hf-float-right md-text--disabled'
      : 'md-inline-block md-btn md-btn--icon hf-float-right'

    const brandingHeader =
      user.isEnterprise && singularName === 'guidebook'
        ? 'Host introduction, branding, and templates'
        : 'Host introduction and branding'
    const templateEntry =
      user.isEnterprise && singularName === 'guidebook' ? (
        <div className="md-grid md-grid--no-spacing" id="template">
          <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet md-cell--middle">
            <FontIcon className="md-inline-block md-btn md-btn--icon hf-float-right">
              developer_board
            </FontIcon>
          </div>
          <SelectForm
            className="md-cell md-cell--9 md-cell--5-tablet md-cell--3-phone"
            label="Template"
            model=".template.id"
            itemLabel="name"
            itemValue="id"
          >
            {sortData(templates.data, 'name')}
          </SelectForm>
          <div className="md-cell md-cell--2 md-cell--2-tablet md-cell--1-phone md-cell--middle"></div>
        </div>
      ) : null

    const templateGuidebooksEntry =
      user.isPaidHost && singularName === 'template' ? (
        <div className="hf-editable-card-tabs">
          <ManageSelectedGuidebooks
            ownerPluralName="templates"
            ownerSingularName="template"
            cardType="Template"
            active={true}
            showHeading={false}
          />
        </div>
      ) : null

    let themeEntry = null
    if (user.canTheme) {
      themeEntry = (
        <div className="md-grid md-grid--no-spacing" id="theme">
          <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet md-cell--middle">
            <FontIcon className="md-inline-block md-btn md-btn--icon hf-float-right">
              settings
            </FontIcon>
          </div>
          <SelectForm
            className="md-cell md-cell--9 md-cell--5-tablet md-cell--3-phone"
            label="Theme"
            model=".theme.id"
            itemLabel="name"
            itemValue="id"
          >
            {sortData(themes.data, 'name')}
          </SelectForm>
          <div className="md-cell md-cell--2 md-cell--2-tablet md-cell--1-phone md-cell--middle md-text-center">
            <Button
              onClick={(e) => {
                this.createCard('theme', 'themes')
              }}
              icon
            >
              add
            </Button>
            {this.cardSelected(edit_item.theme) ? (
              <Button
                onClick={(e) => {
                  this.editCard('theme', 'themes')
                }}
                icon
              >
                edit
              </Button>
            ) : null}
          </div>
        </div>
      )
    } else {
      themeEntry = (
        <div
          className={'md-grid md-grid--no-spacing hf-disabled-input'}
          id="theme"
        >
          <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet md-cell--middle">
            <FontIcon className={customUrlIconClass}>settings</FontIcon>
          </div>
          <SelectField
            id="selectDefaultPath"
            placeholder="Which theme would you like to apply to this guidebook?"
            menuItems={[]}
            itemLabel="label"
            itemValue="value"
            disabled={true}
            className="md-cell md-cell--9 md-cell--5-tablet md-cell--3-phone"
          />
          <div className="md-cell md-cell--2 md-cell--2-tablet md-cell--1-phone md-cell--middle md-text-center">
            {customUrlCta}
          </div>
        </div>
      )
    }

    let domainEntry = null
    // if the user can use domains and the guidebook is not a draft
    // OR if the guidebook already has a domain for some reason even if it's in draft, we want to enable this control so it can be remov
    if (user.canUseDomain && (edit_item.is_published || domain)) {
      domainEntry = (
        <div className="md-grid md-grid--no-spacing" id="domain">
          <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet md-cell--middle">
            <FontIcon className="md-inline-block md-btn md-btn--icon hf-float-right">
              domain
            </FontIcon>
          </div>
          <SelectForm
            className="md-cell md-cell--9 md-cell--5-tablet md-cell--3-phone"
            label="Domain (does not apply to drafts)"
            model=".domain.id"
            itemLabel="domain"
            itemValue="id"
            onChange={this.handleDomainChange}
          >
            {sortData(domains.data, 'domain')}
          </SelectForm>
          <div className="md-cell md-cell--2 md-cell--2-tablet md-cell--1-phone"></div>
        </div>
      )
    } else {
      const placeholder = edit_item.is_published
        ? 'Upgrade to add a custom domain to this guidebook'
        : 'You must first publish your guidebook before adding a custom domain'
      domainEntry = (
        <div
          className={'md-grid md-grid--no-spacing hf-disabled-input'}
          id="domain"
        >
          <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet md-cell--middle">
            <FontIcon className={customUrlIconClass}>domain</FontIcon>
          </div>
          <SelectField
            id="selectDefaultPath"
            placeholder={placeholder}
            menuItems={[]}
            itemLabel="label"
            itemValue="value"
            disabled={true}
            className="md-cell md-cell--9 md-cell--5-tablet md-cell--3-phone"
          />
          <div className="md-cell md-cell--2 md-cell--2-tablet md-cell--1-phone md-cell--middle md-text-center">
            {customUrlCta}
          </div>
        </div>
      )
    }

    let saveButton = null
    if (user.isPaidHost && singularName === 'template') {
      saveButton = (
        <Button
          raised
          primary
          type="button"
          onClick={(e) => {
            self.handleSaveTemplate(edit_item)
          }}
        >
          SAVE TEMPLATE
        </Button>
      )
    }

    const publishButton =
      singularName === 'template' ? null : (
        <Button
          raised
          primary
          type="button"
          onClick={(e) => {
            self.handlePublished(edit_item, true)
          }}
        >
          {edit_item.is_published ? 'PUBLISH CHANGES' : 'SAVE & PUBLISH'}
        </Button>
      )

    const guidebookStatus =
      singularName === 'template' ? null : (
        <GuidebookStatus
          gbKey={edit_item.key}
          isPublished={edit_item.is_published}
          onUnpublish={() => {
            self.handlePublished(edit_item, false)
          }}
          domain={edit_item.domain}
          locales={edit_item.locales}
        />
      )

    const copyUrl = (e) => {
      this.props.dispatch(addToast('The URL has been copied to the clipboard.'))
    }

    let defaultPaths = [
      { value: '', label: 'Based on tab order (default)' },
      { value: '/arrival', label: 'Arrival' },
      { value: '/departure', label: 'Departure' }
    ]
    let currentTabs = []
    // fill in all the other possible tabs based on what's available in the guidebook
    this.addDefaultPaths(defaultPaths, currentTabs, edit_item)

    const gbLink = buildGuidebookUrl(
      edit_item.key,
      null,
      null,
      false,
      domain,
      edit_item.is_published
    )
    const roLink = buildGuidebookUrl(
      edit_item.recs_only_key,
      null,
      true,
      false,
      domain,
      edit_item.is_published
    )
    const displayUrl = (
      <div className="md-cell md-cell--11 md-cell--1-desktop-offset">
        <br />
        <span className="md-text--secondary">Sharable URL</span>
        <br />
        <CopyToClipboard
          className="show-pointer hf-copy-link"
          text={gbLink || ''}
          onCopy={(e) => {
            copyUrl(e)
          }}
        >
          <span>{gbLink}</span>
        </CopyToClipboard>
      </div>
    )

    const displayRecsOnlyUrl = edit_item.recs_only_key ? (
      <div className="md-cell md-cell--11 md-cell--1-desktop-offset">
        <br />
        <span className="md-text--secondary">Recommendations-only URL</span>
        <br />
        <CopyToClipboard
          className="show-pointer hf-copy-link"
          text={roLink || ''}
          onCopy={(e) => {
            copyUrl(e)
          }}
        >
          <span>{roLink}</span>
        </CopyToClipboard>
      </div>
    ) : null

    const langSpecificUrls =
      edit_item.locales.length > 0 ? (
        <div className="md-cell md-cell--11 md-cell--1-desktop-offset">
          <br />
          <span className="md-text--secondary">Language Specific URLs</span>
          <br />
          {edit_item.locales.map((loc) => {
            let locUrl = buildGuidebookUrl(
              edit_item.key,
              loc,
              null,
              false,
              domain,
              edit_item.is_published
            )
            const copyLink = edit_item.key ? (
              <CopyToClipboard
                className="show-pointer hf-copy-link"
                text={locUrl}
                onCopy={(e) => {
                  copyUrl(e)
                }}
              >
                <span>{locUrl}</span>
              </CopyToClipboard>
            ) : null

            return (
              <div
                key={`locale-url-${loc}`}
                style={{
                  display: 'flex',
                  columnGap: '8px'
                }}
              >
                <span className="md-text--secondary">
                  {formatMessage(localeMessages[loc])}:
                </span>
                {copyLink}
              </div>
            )
          })}
        </div>
      ) : null

    const vcsContent = (
      <div className="md-grid">
        <div className="md-cell md-cell--12"></div>
        <div className="md-cell md-cell--8 md-cell--2-desktop-offset">
          <Media>
            <img
              src="https://storage.googleapis.com/hostfully-wp-1/480x270_echo_dot.jpg"
              alt=""
            />
          </Media>
        </div>
        <div className="md-cell md-cell--12 md-text-center">
          <h2 className="md-font-bold">Why add voice to your Guidebook?</h2>
        </div>
        <div className="md-cell md-cell--12 md-text-center">
          <h3 className="md-title">
            Provide a conversational virtual concierge for your vacation rental.
          </h3>
          <p className="md-body-1">
            You can now extend your content and make it available through an
            Amazon Echo or Google Home device. Alexa or Google will provide your
            expert local knowledge in an interactive voice conversation.
            Differentiate your property with voice technology.
          </p>
          <p className="md-title md-font-bold">
            Free for 3 months, and then 20% off!
          </p>
        </div>
      </div>
    )
    const linkModal = (
      <LinkModal
        linkText="Virtual Concierge Service"
        modalContent={vcsContent}
      />
    )
    const vcsStatus = this.state.vcsConnected ? (
      <div className="md-grid md-grid--no-spacing">
        <div className="md-cell md-cell--11 md-cell--1-desktop-offset">
          Hurray! This guidebook is already connected to Virtual Concierge
          Service.
        </div>
        <div className="md-cell md-cell--11 md-cell--1-desktop-offset">
          <Button
            raised
            primary
            type="button"
            className="hf-button-margin"
            onClick={this.handleVCSRedirect}
          >
            Go to Virtual Concierge Service
          </Button>
          <Button
            raised
            secondary
            type="button"
            className="hf-button-margin hf-add-another"
            onClick={this.handleVCSImport}
          >
            Reimport Guidebook Data
          </Button>
        </div>
      </div>
    ) : (
      <div className="md-grid md-grid--no-spacing">
        <div className="md-cell md-cell--11 md-cell--1-desktop-offset">
          You can now deliver a stand out service for your guests - a virtual
          concierge. Put an Echo, Dot, Google Home, or Mini in your vacation
          rental, and Alexa or Google will provide information from your
          guidebook in an interactive voice conversation. <br />
          <br />
          Click below to activate this feature through our partner, {linkModal}.
          <br />
          Note: this will open a third-party website in a new window.
        </div>
        <div className="md-cell md-cell--11 md-cell--1-desktop-offset">
          <Button
            raised
            primary
            type="button"
            className="hf-button-margin"
            onClick={this.handleVCSClick}
          >
            Activate Voice Service
          </Button>
        </div>
      </div>
    )
    const vcsCard = (
      <Card className="hf-wider-selection-control-container">
        <CardTitle
          avatar={
            <Avatar
              icon={<FontIcon>volume_up</FontIcon>}
              role="presentation"
              suffix="hflightblue"
            />
          }
          title="Voice Service"
        />
        <CardText>{vcsStatus}</CardText>
      </Card>
    )

    const manageMarketplace = (
      <MarketplaceSection
        loaded={this.state.servicesLoaded && this.state.allServicesLoaded}
        services={this.state.services}
        allServices={this.state.allServices}
        servicesError={this.state.servicesError}
        allServicesError={this.state.allServicesError}
        onSelectionChanged={this.handleMarketplaceSelectionChange}
        onSort={this.handleMarketplaceSort}
        onUpgradeClick={this.showDialog}
      />
    )

    const manageListings = user.isPaidHost ?  (
      <ManageListings {...this.props} />
    ) : (
      <div className="md-grid md-grid--no-spacing">
        <div className="md-cell md-cell--12 md-text-center">
          <Button
            onClick={this.props.onUpgradeClick}
            iconChildren="account_circle"
            flat
            primary
          >
            Activate
          </Button>
        </div>
        <div className="md-cell md-cell--12 md-text-center">
          <p>Upgrade to a paid plan to add a Book Again section to your guidebook</p>
        </div>
      </div>
    )

    const cancelButton = (
      <Button
        flat
        secondary
        type="button"
        onClick={(e) => {
          this.cancelClick()
        }}
      >
        CANCEL
      </Button>
    )

    const validators = {
      '': {
        // Form-level validator
        valid: () => {
          return this.formHasErrors(formErrors)
        }
      },
      name: {
        required: (val) => val && val.length
      },
      'address.street': {
        //required: (val) => val && val.length
      },
      'address.locality': {
        required: (val) => val && val.length
      },
      'address.country_code': {
        required: (val) => val && val.length
      },
      'address.lat': {
        required: (val) => val && val !== ''
      },
      'domain.id': {
        no_draft: (val) => !val || this.noDomainOnDraft(val)
      },
      key: {
        invalidKey: (val) => this.customUrlIsValid(val),
        numericKey: (val) => !isNumericKey(val)
      },
      recs_only_key: {
        invalidKey: (val) => !val || this.customUrlIsValid(val),
        numericKey: (val) => !isNumericKey(val)
      }
    }

    const stepOneTitle = 'Guidebook Address, Name, and Image'
    const stepTwoTitle = 'Arrival & Departure'
    const stepThreeTitle = user.isPaidHost
      ? 'House Manual, Recommendations, Book Again, and Marketplace'
      : 'House Manual & Recommendations'
    const stepFourTitle = 'Customization'
    const stepFiveTitle =
      singularName === 'guidebook'
        ? 'Recommendations-only Guidebook'
        : 'Assign Guidebooks'
    const stepOneSubtitle = `Name your ${singularName} and locate it on the map`
    const stepTwoSubtitle = 'Create and assign arrival & departure cards'
    const stepThreeSubtitle = user.isPaidHost
      ? 'Create, assign, and reorder house manual, recommendation, book again, and marketplace cards'
      : 'Create, assign, and reorder house manual and recommendation cards'
    const stepFourSubtitle =
      'Add your own branding and configure settings and integrations'
    const stepFiveSubtitle =
      singularName === 'guidebook'
        ? 'Create a recommendations-only version of this guidebook'
        : 'Apply this template to some of your guidebooks!'

    const gbWizard =
      singularName === 'guidebook' && this.state.useWizard !== false ? (
        <GuidebookWizard
          address={gb_address}
          image={image}
          editItem={this.props.edit_item}
          closeWizard={this.closeWizard}
          editChildren={this.props.edit_children}
          generateRecsAndNext={this.generateRecsAndNext}
          previewGuidebook={this.previewGuidebook}
          nextStep={this.nextStep}
          saveAndCloseWizard={this.saveAndCloseWizard}
          saveInitialAndReloadWizard={this.saveInitialAndReloadWizard}
          singularName={singularName}
          skipStep={this.skipStep}
          toggleUseWizard={this.toggleUseWizard}
          wizardStep={this.state.wizardStep}
        />
      ) : null

    return (
      <Form
        model={editName}
        onSubmit={(v) => {
          self.handleSubmit(v)
        }}
        onSubmitFailed={() => {
          self.requestAutosave = false
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            return this.handleEnter(e)
          }
        }}
        validators={validators}
      >
        <div className="md-grid">
          <div className="md-cell md-cell--12 md-text-right">
            {guidebookStatus}
          </div>
        </div>
        <div className="hf-stepper">
          <GuidebookStepper
            step={'1'}
            title={stepOneTitle}
            subtitle={stepOneSubtitle}
            open={this.state.stepperOpen[1]}
            enabled={this.state.stepperEnabled[1]}
            onClick={this.handleStepClick}
          >
            <div className="hf-step-content md-grid">
              <div className="md-cell md-cell--12 hf-headline-margin">
                {formErrors['key']}
              </div>
              <div className="md-cell md-cell--12 hf-headline-margin">
                <AddressForm
                  editModel={editName}
                  address={gb_address}
                  image={image}
                />
              </div>
              <div className="md-cell md-cell--12 hf-headline-margin">
                <div className="md-grid">
                  <div className="md-cell md-cell--12">
                    <h2 className="md-headline">
                      Name this {singularName} so you can find it later
                    </h2>
                  </div>
                  <div className="md-cell md-cell--12">
                    <InputTextField
                      model=".name"
                      id="name"
                      label="Descriptive Name *"
                    />
                  </div>
                  <div className="md-cell md-cell--12">
                    {formErrors['name']}
                  </div>
                </div>
              </div>
              <div className="md-cell md-cell--12 hf-headline-margin">
                <div className="md-grid">
                  <div className="md-cell md-cell--12">
                    <h2 className="md-headline">
                      What image do you want to feature on the {singularName}?
                    </h2>
                  </div>
                  <div className="md-cell md-cell--4">{preview_image}</div>
                  <div className="md-cell md-cell--8">
                    <PickerOverlay
                      apikey={filestackKey}
                      componentDisplayMode={{
                        type: 'button',
                        customText: 'Select Image',
                        customClass:
                          'md-inline-block md-btn md-btn--raised md-background--primary md-background--primary-hover md-pointer--hover md-btn--text md-btn--raised-pressed'
                      }}
                      actionOptions={filestackOptions}
                      onSuccess={(result) => {
                        this.imageUploaded(result, 'image')
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="hf-step-footer md-grid">
              <div className="md-cell md-cell--12 hf-headline-margin">
                <div>{formErrors['formwide']}</div>
                <div>{this.renderServerErrors()}</div>
                <div>{formErrors['key']}</div>
                <div>{formErrors['name']}</div>
                <div>{formErrors['address.lat']}</div>
                <div>{formErrors['address.street']}</div>
                <div>{formErrors['address.locality']}</div>
                <div>{formErrors['address.country_code']}</div>
              </div>
            </div>
          </GuidebookStepper>
          <GuidebookStepper
            step="2"
            title={stepTwoTitle}
            subtitle={stepTwoSubtitle}
            open={this.state.stepperOpen[2]}
            enabled={this.state.stepperEnabled[2]}
            onClick={this.handleStepClick}
          >
            {singularName === 'template' ? (
              <p className="md-body-1">
                These cards will be appear on any guidebook(s) which inherit
                this template UNLESS you assign an alternative card on the
                guidebook itself
              </p>
            ) : null}
            <div className="hf-step-content md-grid">
              <div className="md-cell md-cell--12 hf-headline-margin">
                <Card
                  className="hf-wider-selection-control-container"
                  id="arrival"
                >
                  <CardTitle
                    avatar={
                      <Avatar
                        icon={<FontIcon>vpn_key</FontIcon>}
                        role="presentation"
                        suffix="hfred"
                      />
                    }
                    title="Arrival cards"
                  />
                  <CardText>
                    <div className="md-grid md-grid--no-spacing" id="checkin">
                      <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet md-cell--middle">
                        <FontIcon className="md-inline-block md-btn md-btn--icon hf-float-right">
                          access_time
                        </FontIcon>
                      </div>
                      <SelectForm
                        className="md-cell md-cell--9 md-cell--5-tablet md-cell--3-phone"
                        label="Check-in card"
                        model=".checkin.id"
                        itemLabel="label"
                        itemValue="id"
                      >
                        {sortData(checkins.data, 'label')}
                      </SelectForm>
                      <div className="md-cell md-cell--2 md-cell--2-tablet md-cell--1-phone md-cell--middle md-text-center">
                        <Button
                          onClick={(e) => {
                            this.createCard('checkin', 'checkins')
                          }}
                          icon
                        >
                          add
                        </Button>
                        {this.cardSelected(edit_item.checkin) ? (
                          <Button
                            onClick={(e) => {
                              this.editCard('checkin', 'checkins')
                            }}
                            icon
                          >
                            edit
                          </Button>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="md-grid md-grid--no-spacing"
                      id="directions"
                    >
                      <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet md-cell--middle">
                        <FontIcon className="md-inline-block md-btn md-btn--icon hf-float-right">
                          place
                        </FontIcon>
                      </div>
                      <SelectForm
                        className="md-cell md-cell--9 md-cell--5-tablet md-cell--3-phone"
                        label="Directions card"
                        model=".directions.id"
                        itemLabel="label"
                        itemValue="id"
                      >
                        {sortData(directions.data, 'label')}
                      </SelectForm>
                      <div className="md-cell md-cell--2 md-cell--2-tablet md-cell--1-phone md-cell--middle md-text-center">
                        <Button
                          onClick={(e) => {
                            this.createCard('directions', 'directions')
                          }}
                          icon
                        >
                          add
                        </Button>
                        {this.cardSelected(edit_item.directions) ? (
                          <Button
                            onClick={(e) => {
                              this.editCard('directions', 'directions')
                            }}
                            icon
                          >
                            edit
                          </Button>
                        ) : null}
                      </div>
                    </div>
                    <div className="md-grid md-grid--no-spacing" id="parking">
                      <div className="md-cell md-cell--1 md-cell--phone-hidden  md-cell--1-tablet md-cell--middle">
                        <FontIcon className="md-inline-block md-btn md-btn--icon hf-float-right">
                          local_parking
                        </FontIcon>
                      </div>
                      <SelectForm
                        className="md-cell md-cell--9 md-cell--5-tablet md-cell--3-phone"
                        label="Parking card"
                        model=".parking.id"
                        itemLabel="label"
                        itemValue="id"
                      >
                        {sortData(parkings.data, 'label')}
                      </SelectForm>
                      <div className="md-cell md-cell--2 md-cell--2-tablet md-cell--1-phone md-cell--middle md-text-center">
                        <Button
                          onClick={(e) => {
                            this.createCard('parking', 'parkings')
                          }}
                          icon
                        >
                          add
                        </Button>
                        {this.cardSelected(edit_item.parking) ? (
                          <Button
                            onClick={(e) => {
                              this.editCard('parking', 'parkings')
                            }}
                            icon
                          >
                            edit
                          </Button>
                        ) : null}
                      </div>
                    </div>
                    <div className="md-grid md-grid--no-spacing" id="wifi">
                      <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet md-cell--middle">
                        <FontIcon className="md-inline-block md-btn md-btn--icon hf-float-right">
                          wifi
                        </FontIcon>
                      </div>
                      <SelectForm
                        className="md-cell md-cell--9 md-cell--5-tablet md-cell--3-phone"
                        label="Wifi card"
                        model=".wifi.id"
                        itemLabel="label"
                        itemValue="id"
                      >
                        {sortData(wifis.data, 'label')}
                      </SelectForm>
                      <div className="md-cell md-cell--2 md-cell--2-tablet md-cell--1-phone md-cell--middle md-text-center">
                        <Button
                          onClick={(e) => {
                            this.createCard('wifi', 'wifis')
                          }}
                          icon
                        >
                          add
                        </Button>
                        {this.cardSelected(edit_item.wifi) ? (
                          <Button
                            onClick={(e) => {
                              this.editCard('wifi', 'wifis')
                            }}
                            icon
                          >
                            edit
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </CardText>
                </Card>
                <Card className="hf-wider-selection-control-container">
                  <CardTitle
                    avatar={
                      <Avatar
                        icon={<FontIcon>flight_takeoff</FontIcon>}
                        role="presentation"
                        suffix="hfamber"
                      />
                    }
                    title="Departure cards"
                  />
                  <CardText>
                    <div className="md-grid md-grid--no-spacing" id="checkout">
                      <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet md-cell--middle">
                        <FontIcon className="md-inline-block md-btn md-btn--icon hf-float-right">
                          lock_outline
                        </FontIcon>
                      </div>
                      <SelectForm
                        className="md-cell md-cell--9 md-cell--5-tablet md-cell--3-phone"
                        label="Checkout card"
                        model=".checkout.id"
                        itemLabel="label"
                        itemValue="id"
                      >
                        {sortData(checkouts.data, 'label')}
                      </SelectForm>
                      <div className="md-cell md-cell--2 md-cell--2-tablet md-cell--1-phone md-cell--middle md-text-center">
                        <Button
                          onClick={(e) => {
                            this.createCard('checkout', 'checkouts')
                          }}
                          icon
                        >
                          add
                        </Button>
                        {this.cardSelected(edit_item.checkout) ? (
                          <Button
                            onClick={(e) => {
                              this.editCard('checkout', 'checkouts')
                            }}
                            icon
                          >
                            edit
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </CardText>
                </Card>
              </div>
            </div>
            <div className="hf-step-footer md-grid">
              <div className="md-cell md-cell--12 hf-headline-margin">
                <div>{formErrors['formwide']}</div>
                <div>{this.renderServerErrors()}</div>
                <div>{formErrors['key']}</div>
                <div>{formErrors['name']}</div>
                <div>{formErrors['address.lat']}</div>
                <div>{formErrors['address.street']}</div>
                <div>{formErrors['address.locality']}</div>
                <div>{formErrors['address.country_code']}</div>
              </div>
            </div>
          </GuidebookStepper>
          <GuidebookStepper
            step="3"
            title={stepThreeTitle}
            subtitle={stepThreeSubtitle}
            open={this.state.stepperOpen[3]}
            enabled={this.state.stepperEnabled[3]}
            onClick={this.handleStepClick}
          >
            {singularName === 'template' ? (
              <p className="md-body-1">
                These cards will be appear on any guidebook(s) which inherit
                this template in the order specified on the sort tab below,
                however they will appear BELOW any cards which are assigned
                directly to the guidebook itself.
              </p>
            ) : null}
            <div className="hf-step-content md-grid">
              <div className="md-cell md-cell--12 hf-headline-margin">
                <Card className="hf-wider-selection-control-container">
                  <CardTitle
                    avatar={
                      <Avatar
                        icon={<FontIcon>home</FontIcon>}
                        role="presentation"
                        suffix="hforange"
                      />
                    }
                    title="House manual cards"
                  />
                  <ManageInformations {...this.props} />
                </Card>
                <Card className="hf-wider-selection-control-container">
                  <CardTitle
                    avatar={
                      <Avatar
                        icon={<FontIcon>favorite</FontIcon>}
                        role="presentation"
                        suffix="hfgreen"
                      />
                    }
                    title="Recommendation cards"
                  />
                  <ManageRecommendations
                    defaultOpen={this.state.openRecsWizard}
                    onWizardClick={(e) => {
                      self.handleContinue(edit_item, 4)
                    }}
                    {...this.props}
                  />
                </Card>
                <Card className="hf-wider-selection-control-container">
                  <CardTitle
                    avatar={
                      <Avatar
                        icon={<FontIcon>location_on</FontIcon>}
                        role="presentation"
                        suffix="hfbrown"
                      />
                    }
                    title="Book Again Listings"
                  />
                  {manageListings}
                </Card>
                {manageMarketplace}
              </div>
            </div>
            <div className="hf-step-footer md-grid">
              <div className="md-cell md-cell--12 hf-headline-margin">
                <div>{formErrors['formwide']}</div>
                <div>{this.renderServerErrors()}</div>
                <div>{formErrors['key']}</div>
                <div>{formErrors['name']}</div>
                <div>{formErrors['address.lat']}</div>
                <div>{formErrors['address.street']}</div>
                <div>{formErrors['address.locality']}</div>
                <div>{formErrors['address.country_code']}</div>
              </div>
            </div>
          </GuidebookStepper>
          <GuidebookStepper
            step="4"
            title={stepFourTitle}
            subtitle={stepFourSubtitle}
            open={this.state.stepperOpen[4]}
            enabled={this.state.stepperEnabled[4]}
            onClick={this.handleStepClick}
          >
            {singularName !== 'template' && user.isEnterprise ? (
              <p className="md-body-1">
                If you have the ability to create templates, you'll also be able
                to assign one of them to this guidebook here
              </p>
            ) : null}
            <div className="hf-step-content md-grid">
              <div className="md-cell md-cell--12 hf-headline-margin">
                <Card className="hf-wider-selection-control-container">
                  <CardTitle
                    avatar={
                      <Avatar
                        icon={<FontIcon>face</FontIcon>}
                        role="presentation"
                        suffix="hfamber"
                      />
                    }
                    title={brandingHeader}
                  />
                  <CardText>
                    <div
                      className="md-grid md-grid--no-spacing"
                      id="host_intro"
                    >
                      <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet md-cell--middle">
                        <FontIcon className="md-inline-block md-btn md-btn--icon hf-float-right">
                          face
                        </FontIcon>
                      </div>
                      <SelectForm
                        className="md-cell md-cell--9 md-cell--5-tablet md-cell--3-phone md-cell--middle"
                        label="Host Introduction"
                        model=".host_intro.id"
                        itemLabel="label"
                        itemValue="id"
                      >
                        {sortData(hostintros.data, 'label')}
                      </SelectForm>
                      <div className="md-cell md-cell--2 md-cell--2-tablet md-cell--1-phone md-cell--middle md-text-center">
                        <Button
                          onClick={(e) => {
                            this.createCard('host_intro', 'hostintros')
                          }}
                          icon
                        >
                          add
                        </Button>
                        {this.cardSelected(edit_item.host_intro) ? (
                          <Button
                            onClick={(e) => {
                              this.editCard('host_intro', 'hostintros')
                            }}
                            icon
                          >
                            edit
                          </Button>
                        ) : null}
                      </div>
                    </div>
                    {themeEntry}
                    {domainEntry}
                    {templateEntry}
                  </CardText>
                </Card>
                <Card className="hf-wider-selection-control-container">
                  <CardTitle
                    avatar={
                      <Avatar
                        icon={<FontIcon>dns</FontIcon>}
                        role="presentation"
                        suffix="hfpurple"
                      />
                    }
                    title="Language and link settings"
                  />
                  <CardText>
                    <LocaleForm editModel={editName} disabled={disableInput} />
                    <div
                      className={
                        'md-grid md-grid--no-spacing ' +
                        (disableInput ? 'hf-disabled-input' : '')
                      }
                      id="url"
                    >
                      <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet md-cell--middle">
                        <FontIcon className={customUrlIconClass}>
                          find_in_page
                        </FontIcon>
                      </div>
                      <SelectField
                        id="selectDefaultPath"
                        placeholder="Which tab should show by default"
                        menuItems={sortData(defaultPaths, 'label')}
                        itemLabel="label"
                        itemValue="value"
                        onChange={this.handleDefaultChange}
                        disabled={disableInput}
                        className="md-cell md-cell--9 md-cell--5-tablet md-cell--3-phone"
                        value={edit_item.default_path}
                      />
                      <div className="md-cell md-cell--2 md-cell--2-tablet md-cell--1-phone md-cell--middle md-text-center">
                        {customUrlCta}
                      </div>
                    </div>
                    <div
                      className={
                        'md-grid md-grid--no-spacing ' +
                        (disableInput ? 'hf-disabled-input' : '')
                      }
                      id="url"
                    >
                      <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet md-cell--middle">
                        <FontIcon className={customUrlIconClass}>link</FontIcon>
                      </div>
                      <div className={customUrlWrapperClass}>
                        <InputTextField
                          model=".key"
                          id="key"
                          label="Custom URL Key"
                          disabled={disableInput}
                          preventWhitespace
                        />
                        {formErrors['key']}
                      </div>
                      <div className="md-cell md-cell--2 md-cell--2-tablet md-cell--1-phone md-cell--middle md-text-center">
                        {customUrlCta}
                      </div>
                      {displayUrl}
                      {langSpecificUrls}
                    </div>
                  </CardText>
                </Card>
                {this.renderConnectedProperties()}
                {this.renderSecureSwitch()}
                {vcsCard}
              </div>
            </div>
            <div className="hf-step-footer md-grid">
              <div className="md-cell md-cell--12 hf-headline-margin">
                <div>{formErrors['formwide']}</div>
                <div>{this.renderServerErrors()}</div>
                <div>{formErrors['key']}</div>
                <div>{formErrors['domain.id']}</div>
                <div>{formErrors['name']}</div>
                <div>{formErrors['address.lat']}</div>
                <div>{formErrors['address.street']}</div>
                <div>{formErrors['address.locality']}</div>
                <div>{formErrors['address.country_code']}</div>
              </div>
            </div>
          </GuidebookStepper>
          {singularName === 'guidebook' ? (
            <GuidebookStepper
              step="5"
              title={stepFiveTitle}
              subtitle={stepFiveSubtitle}
              open={this.state.stepperOpen[5]}
              enabled={this.state.stepperEnabled[5]}
              onClick={this.handleStepClick}
            >
              <p className="md-body-1">
                Want to share your recommendations with others without exposing
                any of your listing information?
                <br />
                Create a recommendations-only version of your guidebook that you
                can share widely!
              </p>
              <div className="hf-step-content md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <Card className="hf-wider-selection-control-container">
                    <CardTitle
                      avatar={
                        <Avatar
                          icon={<FontIcon>favorite</FontIcon>}
                          alt=""
                          suffix="hfred"
                        />
                      }
                      title="Recommendations-only Version"
                    />
                    <CardText>
                      <div className="md-grid md-grid--no-spacing">
                        <div className="md-cell md-cell--1 md-cell--phone-hidden"></div>
                        <div className="md-cell md-cell--11 md-cell--7-tablet md-cell--4-phone">
                          <h2>
                            Do you want to enable a recommendations-only version
                            of this guidebook?
                          </h2>
                          <div className="md-grid md-grid--no-spacing">
                            <div className="md-cell md-cell--1 md-cell--1-phone">
                              <p className="md-text md-text-right hf-switch-left">
                                No
                              </p>
                            </div>
                            <div className="md-cell md-cell--11 md-cell--7-tablet md-cell--3-phone">
                              <Switch
                                id="recs_only_enabled"
                                name="recs_only_enabled"
                                checked={edit_item.recs_only_enabled}
                                onChange={this.handleChangeRecsOnlyEnabled}
                                label="Yes"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {edit_item.recs_only_enabled
                        ? [
                            <div
                              className="md-grid md-grid--no-spacing"
                              key="recs-only-info"
                            >
                              <div className="md-cell md-cell--1 md-cell--phone-hidden"></div>
                              <div className="md-cell md-cell--9 md-cell--5-tablet md-cell--middle">
                                <div className="title">
                                  Recommendations-only title:
                                </div>
                                <InputTextField
                                  model=".recs_only_name"
                                  id="recs_only_name"
                                />
                              </div>
                              <div className="md-cell md-cell--2 md-cell--1-tablet md-cell--phone-hidden"></div>
                              <div className="md-cell md-cell--1 md-cell--phone-hidden"></div>
                              <div className="md-cell md-cell--9 md-cell--5-tablet md-cell--middle hf-headline-margin">
                                <div className="title">
                                  Recommendations-only image:
                                </div>
                                <div className="md-grid">
                                  <div className="md-cell md-cell--4">
                                    {preview_recs_only_image}
                                  </div>
                                  <div className="md-cell md-cell--8">
                                    <PickerOverlay
                                      apikey={filestackKey}
                                      componentDisplayMode={{
                                        type: 'button',
                                        customText: 'Select Image',
                                        customClass:
                                          'md-inline-block md-btn md-btn--raised md-background--primary md-background--primary-hover md-pointer--hover md-btn--text md-btn--raised-pressed'
                                      }}
                                      actionOptions={filestackOptions}
                                      onSuccess={(result) => {
                                        this.imageUploaded(
                                          result,
                                          'recs_only_image'
                                        )
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="md-cell md-cell--2 md-cell--1-tablet md-cell--phone-hidden"></div>
                            </div>,
                            <div
                              className={
                                'md-grid md-grid--no-spacing ' +
                                (disableInput ? 'hf-disabled-input' : '')
                              }
                              key="recs-only-url"
                            >
                              <div className="md-cell md-cell--1 md-cell--phone-hidden md-cell--1-tablet md-cell--middle">
                                <FontIcon className={customUrlIconClass}>
                                  link
                                </FontIcon>
                              </div>
                              <div className={customUrlWrapperClass}>
                                <div className="title">
                                  Custom recommendations-only URL key:
                                </div>
                                <InputTextField
                                  model=".recs_only_key"
                                  id="recs_only_key"
                                  disabled={disableInput}
                                  preventWhitespace
                                />
                                {formErrors['recs_only_key']}
                              </div>
                              <div className="md-cell md-cell--2 md-cell--2-tablet md-cell--middle">
                                {customUrlCta}
                              </div>
                              {displayRecsOnlyUrl}
                              {/*langSpecificRecsOnlyUrls*/}
                            </div>
                          ]
                        : null}
                    </CardText>
                  </Card>
                </div>
              </div>
            </GuidebookStepper>
          ) : null}
          {singularName === 'template' ? (
            <GuidebookStepper
              step="5"
              title={stepFiveTitle}
              subtitle={stepFiveSubtitle}
              open={this.state.stepperOpen[5]}
              enabled={this.state.stepperEnabled[5]}
              onClick={this.handleStepClick}
            >
              <p className="md-body-1">
                If you don't have any yet, you'll be able to select this
                template when you create one
              </p>
              <div className="hf-step-content md-grid">
                <div className="md-cell md-cell--12 hf-headline-margin">
                  <Card className="hf-wider-selection-control-container">
                    <CardTitle
                      avatar={
                        <Avatar
                          icon={<FontIcon>import_contacts</FontIcon>}
                          alt=""
                          suffix="hfred"
                        />
                      }
                      title="Assign Guidebooks"
                    />
                    {templateGuidebooksEntry}
                  </Card>
                </div>
              </div>
            </GuidebookStepper>
          ) : null}
        </div>
        <div className="md-grid">
          <div className="md-cell md-cell--12 hf-error-wrapper">
            <div>{formErrors['formwide']}</div>
            <div>{this.renderServerErrors(true)}</div>
            <div>{formErrors['key']}</div>
            <div>{formErrors['recs_only_key']}</div>
            <div>{formErrors['name']}</div>
            <div>{formErrors['address.lat']}</div>
            <div>{formErrors['address.street']}</div>
            <div>{formErrors['address.locality']}</div>
            <div>{formErrors['address.country_code']}</div>
          </div>
        </div>
        <div className="md-cell hf-sticky hf-step-actions">
          <Button
            raised
            primary
            type="button"
            onClick={() => {
              this.handleContinue(edit_item, 4)
            }}
          >
            SAVE
          </Button>
          {publishButton}
          {saveButton}
          {cancelButton}
        </div>
        <DialogContainer
          id="simple-action-dialog"
          visible={visible}
          onHide={this.hideDialog}
          actions={actions}
          title="Upgrade your plan?"
        >
          <p>
            You will be redirected to another page, save your work before
            continuing
          </p>
        </DialogContainer>
        {this.renderUpgradeDialog()}
        {gbWizard}
      </Form>
    )
  }
}

GuidebookForm.propTypes = propTypes

function mapStateToProps(state, props) {
  const { singularName } = props
  const listData = state.list
  const edit_item = state['edit_' + singularName]
  const edit_children = {
    checkin: state['edit_checkin'],
    checkout: state['edit_checkout'],
    directions: state['edit_directions'],
    hostintro: state['edit_hostintro'],
    information: state['edit_information'],
    parking: state['edit_parking'],
    wifi: state['edit_wifi']
  }
  const data = state.edit_item[singularName]
  const edit_form = state.forms['edit_' + singularName]
  const address = edit_item.address
  const image = edit_item.image
  const recs_only_image = edit_item.recs_only_image
  return {
    isLoading: data.isLoading,
    hasErrored: data.hasErrored,
    errors: data.errors,
    edit_item: edit_item,
    edit_children: edit_children,
    address,
    image,
    recs_only_image,
    templates: listData.templates,
    checkins: listData.checkins,
    checkouts: listData.checkouts,
    directions: listData.directions,
    domains: listData.domains,
    hostintros: listData.hostintros,
    parkings: listData.parkings,
    themes: listData.themes,
    wifis: listData.wifis,
    informations: listData.informations,
    recommendations: listData.recommendations,
    edit_form: edit_form
  }
}

export default withCookies(connect(mapStateToProps)(injectIntl(GuidebookForm)))
