export default {
  bookingsyncrental: {
    isLoading: false,
    hasErrored: false,
    data: {}
  },
  checkin: {
    isLoading: false,
    hasErrored: false,
    data: {
      locales: [],
      checkin_time: '2:00 PM',
      checkin_end_time: '10:00 PM',
      checkin_text: ''
    }
  },
  checkout: {
    isLoading: false,
    hasErrored: false,
    data: {
      locales: [],
      checkout_time: '11:00 AM',
      checkout_text: ''
    }
  },
  domain: {
    isLoading: false,
    hasErrored: false,
    data: {
      domain: '',
      status: 'pending'
    }
  },
  directions: {
    isLoading: false,
    hasErrored: false,
    data: {
      additional_directions: '',
      locales: []
    }
  },
  guidebook: {
    isLoading: false,
    autoSaving: false,
    hasErrored: false,
    data: {
      locales: [],
      informations: [],
      listings: [],
      recommendations: [],
      address: {},
      checkin: {},
      checkout: {},
      directions: {},
      host_intro: {},
      parking: {},
      theme: {},
      wifi: {},
      template: {},
      is_published: false
    }
  },
  hostintro: {
    isLoading: false,
    hasErrored: false,
    data: {
      locales: [],
      host_phone: [''],
      guidebooks: []
    }
  },
  information: {
    isLoading: false,
    hasErrored: false,
    data: {
      template: 3,
      content: '',
      content_txn: {},
      guidebooks: [],
      locales: []
    }
  },
  listing: {
    isLoading: false,
    hasErrored: false,
    data: {
      name: '',
      name_txn: {},
      locales: [],
      address: {},
      description: '',
      description_txn: {},
      booking_link: '',
      guidebooks: []
    }
  },
  parking: {
    isLoading: false,
    hasErrored: false,
    data: {
      locales: [],
      parking_text: ''
    }
  },
  recommendation: {
    isLoading: false,
    hasErrored: false,
    data: {
      name: '',
      locales: [],
      address: {},
      category: {},
      why_recommended: '',
      why_recommended_txn: {},
      content: '',
      content_txn: {},
      guidebooks: []
    }
  },
  template: {
    isLoading: false,
    autoSaving: false,
    hasErrored: false,
    data: {
      locales: [],
      informations: [],
      listings: [],
      recommendations: [],
      address: {},
      checkin: {},
      checkout: {},
      directions: {},
      host_intro: {},
      parking: {},
      theme: {},
      wifi: {},
      is_published: true
    }
  },
  theme: {
    isLoading: false,
    hasErrored: false,
    data: {
      locales: [],
      sponsored_count: 8,
      show_babyquip: false,
      show_ground_transport: true,
      show_weather: false,
      splash_button_text: 'I agree',
      ai_label: 'AI Planner',
      itinerary_title: 'Itinerary Generator',
      itinerary_desc: 'Let AI plan your trip.',
      itinerary_image: 'https://cdn.filestackcontent.com/m2gfGcCtS2mkoXBFD49v'
    }
  },
  user_setting: {
    isLoading: false,
    hasErrored: false,
    data: {
      viator_partner_id: ''
    }
  },
  token: {
    isLoading: false,
    hasErrored: false,
    data: {
      token: '',
      data: {},
      is_enabled: true
    }
  },
  wifi: {
    isLoading: false,
    hasErrored: false,
    data: {
      locales: [],
      wifi_provided: true,
      cafe_address: '',
      external_network_name: '',
      network_name: '',
      wifi_password: ''
    }
  },
  category: {
    isLoading: false,
    hasErrored: false,
    data: {}
  }
}
